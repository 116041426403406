import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { Button, DropdownMenu, Icon, List, ListItem } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import styles from '../styles.pcss';
import { FilesById } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import EditableField from '../../../SmsPages/SmsPageSetup/components/SenderName/CreateSenderName/components/EditableFields';

const FileInList = observer(({
  document,
  idx,
  id,
  onReplaceFile,
  onDeleteFile,
  onFileEdit,
}: {
  document: FilesById,
  idx: number,
  id: string,
  onReplaceFile: (idFile: string,
    idxFile: number,
    file: FileList | null,
    existFile?: Omit<FilesById, 'file'>) => void,
  onDeleteFile: (catIdx: FilesById, catId: number | string) => void,
  onFileEdit: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void,
}) => {
  const buttonRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <ListItem className={styles.listItem}>
      <span>{idx + 1}</span>
      <span className={styles.documentHolder}>
        <EditableField
          setIsEditable={setIsEditable}
          document={document}
          isEditable={isEditable}
          onFileEdit={onFileEdit}
        />
        <Button
          variant="plain"
          className={styles.moreBtn}
          ref={buttonRef}
          onClick={() => setShowMenu(e => !e)}
          type="button"
        >
          <Icon iconName={Icons.MoreHoriz} />
          {
            showMenu &&
            <DropdownMenu onOutsideClick={() => setShowMenu(false)} targetElement={buttonRef} align="left">
              <List className={styles.listFile}>
                <ListItem className={styles.editFileList}>
                  <Button
                    startIcon={<Icon iconName={Icons.Edit} />}
                    className={styles.editFileBtn}
                    onClick={() => setIsEditable(true)}
                  >
                    Редактировать название
                  </Button>
                </ListItem>
                <ListItem className={styles.editFileList}>
                  <Button
                    startIcon={<Icon iconName={Icons.Change} />}
                    className={styles.editFileBtn}
                    onClick={() => inputFileRef?.current?.click()}
                  >
                    <input
                      type="file"
                      onChange={async (e) => {
                        await onReplaceFile(
                          `${document.documentTypeId}`,
                          idx,
                          e.target.files,
                          {
                            customFileName: document.customFileName,
                            documentTypeId: document.documentTypeId,
                            documentTypeName: document.documentTypeName,
                          });
                        setShowMenu(false);
                      }}
                      ref={inputFileRef}
                      style={{ display: 'none' }}
                    />
                    Заменить
                  </Button>
                </ListItem>
                <ListItem className={styles.editFileList}>
                  <Button
                    startIcon={<Icon iconName={Icons.Delete} />}
                    className={styles.editFileBtn}
                    onClick={async () => {
                      setShowMenu(false);
                      await onDeleteFile(document, document.documentTypeId);
                    }}
                  >
                    Удалить
                  </Button>
                </ListItem>
              </List>
            </DropdownMenu>
          }
        </Button>
      </span>
    </ListItem>
  );
});

export default FileInList;
