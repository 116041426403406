import React, { FormEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames/bind';
import { Button } from '@beeline/design-system-react';
import { TSMSUserInfo } from 'store/mobx/TSMS/UserInfo';
import { questionsState } from '../store';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const QuestionsParser = observer(() => {
  useEffect(() => {
    questionsState.getActiveSurvey();
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      questionsState.currentScreen > 0 &&
      questionsState.status !== 'Finished' &&
      questionsState?.surveys?.surveyId
    ) {
      const isFinished = questionsState.currentScreen === questionsState.surveys.questions.length;
      if (isFinished) {
        await questionsState.finishSurvey();
      } else {
        await questionsState.updateSurvey(questionsState?.surveys?.surveyId);
      }
    }
    questionsState.currentScreen += 1;
  };

  return (
    <div className={styles.contentWrapper}>
      {
        questionsState?.surveys?.questions.map((question, idx) => {
          if (idx + 1 !== questionsState.currentScreen) return null;
          return (
            <>
              <h6 key={questionsState.forms?.length} className={styles.title}>{question.questionText}</h6>
              <form
                key={question.questionId}
                onSubmit={onSubmit}
                className={question.answers[0].imageLink !== null ? styles.formImages : undefined}
              >
                {
                  question.questionType === 'Single' &&
                  question.answers[0].imageLink !== null &&
                  question.answers.map((singleQuestion, questionIdx) => {
                    const isSelected = questionsState?.forms?.[idx]?.answerIds?.[0] === singleQuestion.answerId;
                    return (
                      <label
                        htmlFor={`${singleQuestion.answerId}`}
                        key={singleQuestion.answerId}
                        className={cx(
                          styles.radioItem,
                          isSelected && styles.selected,
                          styles.imgCheckbox,
                          styles[`image${questionIdx}`],
                        )}
                        style={{
                          backgroundImage: `url(${singleQuestion.imageLink})`,
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <input
                          type="radio"
                          className={styles.input}
                          name={`${question.questionId}`}
                          value={singleQuestion.answerId}
                          id={`${singleQuestion.answerId}`}
                          onChange={() => {
                            if (!questionsState.forms) {
                              questionsState.forms = [];
                              questionsState.forms[idx] = {
                                questionId: question.questionId,
                                answerIds: [singleQuestion.answerId],
                              };
                            } else {
                              questionsState.forms[idx] = {
                                questionId: question.questionId,
                                answerIds: [singleQuestion.answerId],
                              };
                            }
                          }}
                          checked={questionsState?.forms?.[idx]?.answerIds?.[0] === singleQuestion.answerId}
                        />
                        <span className={styles.header}>{singleQuestion.answerText}</span>
                      </label>
                    );
                  })
                }
                {
                  question.questionType === 'Single' &&
                  question.answers[0].imageLink === null &&
                  question.answers.map(singleQuestion => {
                    const isSelected = questionsState?.forms?.[idx]?.answerIds?.[0] === singleQuestion.answerId;
                    return (
                      <label
                        htmlFor={`${singleQuestion.answerId}`}
                        className={cx(styles.radioItem, isSelected && styles.selected, styles.singleChoice)}
                        key={singleQuestion.answerId}
                      >
                        <input
                          type="radio"
                          className={styles.input}
                          name={`${question.questionId}`}
                          value={singleQuestion.answerId}
                          id={`${singleQuestion.answerId}`}
                          onChange={() => {
                            if (!questionsState.forms) {
                              questionsState.forms = [];
                              questionsState.forms[idx] = {
                                questionId: question.questionId,
                                answerIds: [singleQuestion.answerId],
                              };
                            } else {
                              questionsState.forms[idx] = {
                                questionId: question.questionId,
                                answerIds: [singleQuestion.answerId],
                              };
                            }
                          }}
                          checked={questionsState?.forms?.[idx]?.answerIds?.[0] === singleQuestion.answerId}
                        />
                        {singleQuestion.answerText}
                      </label>
                    );
                  })
                }
                {
                  question.questionType === 'Multiple' &&
                  question.answers.map(singleQuestion => {
                    const isSelected = Array.isArray(toJS(questionsState?.forms?.[idx]?.answerIds)) && (questionsState?.forms?.[idx]?.answerIds as number[])?.includes(singleQuestion.answerId);
                    return (
                      <>
                        <label
                          htmlFor={`${singleQuestion.answerId}`}
                          className={cx(styles.radioItem, isSelected && styles.selected, styles.singleChoice)}
                          key={singleQuestion.answerId}
                      >
                          <input
                            type="checkbox"
                            className={styles.input}
                            name={`${question.questionId}`}
                            value={singleQuestion.answerId}
                            id={`${singleQuestion.answerId}`}
                            checked={isSelected}
                            onChange={() => {
                            if (!questionsState.forms) {
                              questionsState.forms = [];
                            }
                            if (!questionsState.forms[idx]?.answerIds) {
                              questionsState.forms[idx] = {
                                questionId: question.questionId,
                                answerIds: [singleQuestion.answerId],
                              };
                              return;
                            }
                            if (questionsState.forms[idx]?.answerIds && Array.isArray(toJS(questionsState.forms[idx]?.answerIds))) {
                              if ((questionsState.forms[idx].answerIds as number[])?.includes(singleQuestion.answerId)) {
                                (questionsState.forms[idx].answerIds as number[]).remove(singleQuestion.answerId);
                              } else {
                                (questionsState.forms[idx].answerIds as number[]).push(singleQuestion.answerId);
                              }
                            }
                            }}
                        />
                          {singleQuestion.answerText}
                        </label>
                      </>
                    );
                  })
                }
                <div className={styles.buttonsHolder}>
                  {
                    idx > 0 &&
                    <Button onClick={() => {
                      questionsState.currentScreen -= 1;
                    }}>Назад</Button>
                  }
                  {
                    idx < (questionsState?.surveys?.questions?.length || 0) &&
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!(questionsState?.forms?.[idx]?.answerIds?.length)}
                    >
                      Далее
                    </Button>
                  }
                </div>
              </form>
            </>
          );
        })
      }
    </div>
  );
});

export default QuestionsParser;
