import React from 'react';
import { Link } from '@reach/router';
import styles from './styles.pcss';

type Props = {
  label?: string
  to?: string
}
const BackLink: React.FC<Props> = ({ label, to }) => {
  return (
    <Link to={`${to || /my-campaigns/}`} className={styles.backLink}>
      {'<'} {label || 'Назад'}
    </Link>
  );
};

export default BackLink;
