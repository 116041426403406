import React, { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormFieldLabel, GroupTitle } from 'redesignSrc/UI/typography';
import { PureButton } from 'redesignSrc/UI/buttons';
import EditIcon from './edit.svg';
import Arrow from './arrow.svg';
import styles from './styles.pcss';

export const ColumnBlock: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className={classNames(styles.flexColumn1, className)}>
      {children}
    </div>
  );
};

export const ContentBlock: React.FC<{
  label?: string,
  text?: string | React.ReactNode,
  className?: string
  onEdit?: () => void,
}> = ({
  children,
  label,
  text,
  className,
  onEdit,
}) => {
  return (
    <div className={classNames(styles.flexColumn2, className)}>
      <div className={styles.labelWrap}>
        <FormFieldLabel>{label}</FormFieldLabel>
        {onEdit &&
          <PureButton
            className={styles.buttonEdit}
            onClick={onEdit}
          >
            <EditIcon/>
            <span>Редактировать</span>
          </PureButton>
        }
      </div>

      {text ?
        <span className={styles.infoValue}>
          {text}
        </span> : children}
    </div>
  );
};

export const WhiteBlock: React.FC<{ className?: string }> = ({ children, className = '' }) => {
  return (
    <div className={`${styles.whiteBlock} ${className}`}>{children}</div>
  );
};

type WhiteBlockCollapseProps = {
  title?: string | ReactNode,
  className?: string

}

export const WhiteBlockCollapse: React.FC<WhiteBlockCollapseProps> = ({ children, className = '', title }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  const renderTitle = useMemo(() => {
    if (typeof title === 'string') {
      return <GroupTitle>{title}</GroupTitle>;
    }
    return title;
  }, [title]);

  return (
    <div className={`${styles.whiteBlock} ${className}`}>
      <div className={styles.wrapCollapse}>
        {renderTitle}
        <PureButton
          className={classNames(styles.buttonCollapse, !isCollapse && styles.openArrowCollapse)}
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <Arrow className={styles.arrowCollapse}/>
        </PureButton>
      </div>

      {!isCollapse && children}
    </div>
  );
};

