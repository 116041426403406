import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { action } from 'mobx';
import { TypographyWithDangling } from '../../../../UI/beeline/TypographyWithDangling/TypographyWithDangling';
import styles from '../styles.pcss';

const UploadedFiles = observer(({
  title,
  onAddFiles,
  documentTypeId,
  children,
}: {
  title: string,
  onAddFiles: (e:React.ChangeEvent<HTMLInputElement>, id: number | string) => void,
  documentTypeId: number,
  children: React.ReactNode,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={styles.uploadedFile}>
      <div className={styles.addFile}>
        <TypographyWithDangling variant="body2">
          {title}
        </TypographyWithDangling>
        <Button
          variant="plain"
          className={styles.plusItem}
          type="button"
          onClick={() => {
            if (inputRef.current) {
              inputRef?.current?.click();
            }
          }}
        >
          <Icon iconName={Icons.Upload} />
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={action((e) => {
              onAddFiles(e, documentTypeId);
            })}
          />
        </Button>
      </div>
      {children}
    </div>
  );
});

export default UploadedFiles;
