import { action, computed, observable, toJS } from 'mobx';
import { InvoicesType } from 'store/mobx/TSMS/CampaignsTsms/types';
import NewOrder from 'store/mobx/NewOrder';
import Constructor from 'store/mobx/Constructor';
import UserInfo from 'store/mobx/UserInfo';
import { axiosBaseRequestAdTech } from 'requests/helpers';

export type TypeBanCustomer = {
  ban: string,
  companyId: string,
  managerName?: string,
  managerFirstName?: string,
  managerMiddleName?: string,
  managerLastName?: string,
  managerId?: number,
  isPostPay?: boolean,
  email?: string,
  customerId: number,
  customerName: string,
  customerInn?: string,
  techCtn?: null,
  zone: string | null
  inn?: string,
  userId?: number,
}


type OptyonsType = {
  ban?: string | null,
  customerId?: number | null,
  industryId?: number | null
}

const normalizeBan = (data: TypeBanCustomer[]): TypeBanCustomer[] => {
  return data.map(el => ({ ...el, customerInn: el.inn }));
};

export default class ContractSelectionLocalStore {
  @observable bans: TypeBanCustomer[] = [];
  @observable invoices: InvoicesType[] = []
  @observable currentBan: TypeBanCustomer | null = null
  @observable currentInvoice: InvoicesType | null = null
  @observable isNotEffect = false
  @observable isUpdateInvoices = false
  @observable isDelete = false
  @observable isDisableBan = false

  // customerId - передается для фильтра банов по кастомеру при создании кампании из уже созданной аудитории
  @action getBans = async (customerId?: number | null) => {
    const urlBan = UserInfo.isRoleSS ? 'api/manager/bans?Take=99999' : 'api/user/bans';
    try {
      this.bans = await axiosBaseRequestAdTech({ url: urlBan });
      if (!UserInfo.isRoleSS) {
        this.bans = normalizeBan(this.bans);
        if (customerId) {
          this.bans = this.bans.filter(ban => ban.customerId === customerId);
        }
        if (this.bans.length <= 1) {
          this.isDisableBan = true;
        }
      }
      this.currentBan = this.bans.find(el => el.ban === NewOrder.order?.ban) || this.bans[0];
    } catch (e) {
      console.error(e);
    }
  }

  @action setInvoiceForOrder = async (invoiceId: number | null) => {
    await axiosBaseRequestAdTech({
      method: 'PUT',
      url: `/api/orders/${NewOrder.order?.id}/invoice?invoiceId=${invoiceId}`,
    });
    this.isDelete = false;
  }

  @action setBanForOrder = async (companyId: string) => {
    if (NewOrder.order?.id) {
      await axiosBaseRequestAdTech({
        method: 'PUT',
        url: `/api/orders/${NewOrder.order.id}/company?companyId=${companyId}`,
      });
      await NewOrder.getOrder(NewOrder.order.id);
    } else {
      console.info('Нет NewOrder.order.id');
    }
  }

  @action setIndustryForOrder = async (industryId: number | null) => {
    if (NewOrder.order?.id) {
      await axiosBaseRequestAdTech({
        method: 'PUT',
        url: `/api/orders/${NewOrder.order?.id}/industry?industryId=${industryId}`,
      });
      this.isDelete = false;
      await NewOrder.getOrder(NewOrder.order.id);
    } else {
      console.info('Нет NewOrder.order.id');
    }
  }

  @action getInvoices = async (opt: OptyonsType) => {
    try {
      this.isUpdateInvoices = true;
      const response = await axiosBaseRequestAdTech({
        url: `/api/invoices?Statuses=1&IsMulti=true${opt.ban ? `&Ban=${opt.ban}` : ''}${opt.customerId ? `&CustomerId=${opt.customerId}` : ''}${opt.industryId ? `&IndustryId=${opt.industryId}` : ''}`,
      });
      this.invoices = response.data;
      if (NewOrder.order?.invoiceId) {
        this.isNotEffect = true;
        this.currentInvoice = this.invoices.find(el => el.id === NewOrder.order?.invoiceId) || null;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isUpdateInvoices = false;
    }
  }

  @computed get industries() {
    if (this.currentInvoice) {
      const industriesId = this.currentInvoice.industryId;
      return Constructor.industries.filter(el => el.id === industriesId);
    }
    return Constructor.industries;
  }
}

