import { action, computed, observable, reaction, toJS } from 'mobx';
import { navigate } from '@reach/router';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import messagesChainLocalStore from 'redesignSrc/pages/PushPages/PushPageSetup/components/PushMessages/store';
import { toastErrorNotification } from 'modules/toast-notifications';
import StoreT from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import { senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import { replaceVariables } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import { customerDiscounts } from 'store/mobx/CustomerDiscounts/CustomerDiscounts';
import UserInfo from '../UserInfo';
import messageTemplatesStore from '../MessageTemplates';
import digitalBillboardsCityStreets from '../digitalBillboardsCityStreets';
import NewAudiences from '../NewAudiences';
import {
  AudioAdsDetailsType,
  BannerFilesType,
  BillboardDetailsType,
  CurrentOrderType,
  InternetCampaignType,
  IntolerantWords,
  OrderType,
  PushCampaignType,
  SmsCampaignType,
  SocialNetwork,
  TypeCalculete,
  VoiceTargetDetailsType,
  TypeAudioAdsTariffs,
} from './type';

export type ResolutionItem = {
  name: string;
}

export type TypeContextual = {
  url: string;
  geoProfiles: string;
  isYandexProfile: boolean;
  targetActions: string;
  keyAdvantages: string;
  descServices: string;
  adText: string;
  descAudience: string;
  keyWords: string,
  promotionalMaterials: string,
  moderationComment?: string | null,
  gender: string[] | [],
  age: string[] | [],
  geoCityId: number[] | [],
}

export const RESOLUTIONS: ResolutionItem[] = [
  {
    name: '1440x720',
  },
  {
    name: '1248x416',
  },
];

const defaultDigitalBillboardDetails: BillboardDetailsType = {
  purpose: 'Повысить узнаваемость компании/бренда (охват)',
  hasBanner: true,
  bannerText: '',
  viewsCount: 10,
  geoType: 'City',
  billboards: [],
  cities: [],
  bannerFiles: [],
  moderationComment: null,
  campaignId: null,
};

const defaultSmsCampaign: SmsCampaignType = {
  isDelayed: false,
  additionalInfo: null,
  campaignId: null,
  isOtherOperators: false,
  otherOperators: [],
  isParameterized: false,
  message: null,
  messageDescription: null,
  senderName: null,
  senderId: null,
  timeFrom: null,
  timeTo: null,
  shortMessage: null,
  files: [],
  links: {
    link: null,
    shortLink: null,
    isShort: false,
    dynamicPartLink: null,
  },
  moderationComment: null,
  templateId: null,
  isTemplate: false,
  variables: null,
};

const defaultPushCampaign: PushCampaignType = {
  isDelayed: false,
  campaignId: null,
  pages: null,
  targetAction: null,
  notificationType: null,
  targetResource: null,
  timeFrom: 9,
  timeTo: 21,
  comment: null,
  moderationComment: null,
  isShort: false,
};

const defaultAudioAdsDetails: AudioAdsDetailsType = {
  retailerTypes: null,
  retailers: null,
  hasAudio: false,
  text: null,
  tariffId: null,
  voice: null,
  melody: null,
  file: null,
  monthCount: null,
};

const defaultCurrentOrder = {
  audience: null,
  planPrice: 0,
  planMessages: 0,
  segmentId: null,
  startDate: null,
  endDate: null,
  name: '',
  comment: null,
  ban: null,
  industryId: null,
  inn: null,
  customerId: null,
  company: null,
  invoiceId: null,
  isSavedAudience: false,
};

const defaultVoiceTargetDetails: VoiceTargetDetailsType = {
  audienceType: 'NotTarget',
  industryId: null,
  numberType: 'Mobile',
};

const defaultOtmDetails: InternetCampaignType = {
  mediaTypeId: null,
  mediaType: null,
  deviceType: [],
  targetingType: [],
  url: '',
  promotionalMaterials: '',
  geoProfiles: '',
  descAudience: '',
  moderationComment: null,
};

const defaultValuesContextual: TypeContextual = {
  url: '',
  geoProfiles: '',
  isYandexProfile: false,
  targetActions: '',
  keyAdvantages: '',
  descServices: '',
  adText: '',
  descAudience: '',
  keyWords: '',
  promotionalMaterials: '',
  moderationComment: null,
  gender: [],
  age: [],
  geoCityId: [],
};


export const defaultSocialDetails: { [key: string]: any } = {
  channel: 1,
  hasButton: true,
  buttonText: null,
  buttonLink: null,
  fileLink: null,
  isRepost: false,
  file: null,
  text: null,
  viewsCount: 0,
  moderationComment: '',
  segment: {
    gender: null,
    age: null,
    subject: null,
  },
  erir: {
    hasErir: false,
    token: null,
    organization: null,
    inn: null,
    legalAddress: null,
    realAddress: null,
    fio: null,
    okved: null,
  },
  postLink: null,
};

export type NamesList = {
  id: number;
  senderName: string,
  cost?: number,
  status: string,
  moderatedTill: string | null,
}

type OrderFiles = {
  id: number,
  name: string
}

export const PRICE_FOR_BANNER_TEXT = 3500;

class NewOrder {
  // @observable otherOperators = ['mts', 'megafon'];
  // Пока что нужен для ИР чтобы не вызывался сохранение ордера в дебонсе
  @observable isNotSaveOrder: boolean = false
  @observable resolutionsFileLoaded: string[] = [];
  @observable bannerText: string = '';
  @observable budgetType: string = 'planMessages';
  @observable uploadFileForResolutions: { [key: string]: { file: any, fileId: number } } = {};

  @observable orderFiles: OrderFiles[] = [];
  @observable bannerFiles: BannerFilesType[] = [];

  @observable isDelAction: boolean = false;
  @observable isDelActionBillboard: boolean = false;
  @observable isExistDataForBillboard: any = false;

  @observable hasBanner: boolean = true;
  @observable isValidLink: boolean = true;
  @observable isLoadingData: boolean = false;
  @observable nameList: NamesList[] | [] | null = null;

  @observable isSaveToTemplate: boolean = window.localStorage.getItem('isSaveToTemplate') === 'true' || false;
  @observable smsSegmentSizeValid: string = '';
  @observable promoOtm: string | null = null
  @observable isDraft = true
  @observable order: OrderType | null = null

  @observable digitalBillboardDetails: BillboardDetailsType = defaultDigitalBillboardDetails;

  @observable contextAdsDetails: TypeContextual = defaultValuesContextual;

  @observable socialNetworkDetails: SocialNetwork = defaultSocialDetails as SocialNetwork;

  @observable smsCampaign: SmsCampaignType = defaultSmsCampaign
  @observable pushCampaign: PushCampaignType = defaultPushCampaign
  @observable voiceTargetDetails: VoiceTargetDetailsType = defaultVoiceTargetDetails
  @observable audioAdsDetails: AudioAdsDetailsType = defaultAudioAdsDetails
  @observable otmDetails: InternetCampaignType = defaultOtmDetails
  @observable currentOrder: CurrentOrderType = defaultCurrentOrder
  @observable calculateData: TypeCalculete | null = null
  @observable textEstimation = null
  @observable testPhone: string = ''
  @observable intolerantWords: IntolerantWords = {
    stopWords: [],
    cautionWords: [],
  }
  @observable costBase = 0
  @observable countBase = 1
  @observable tooltip = ''
  @observable isLoadCheckWords = false

  @observable audioAdsTariffs: TypeAudioAdsTariffs[] = []
  @observable maxLengthAudioText = 150


  @observable orderBySegment: OrderType | null = null;

  @action resetIntolerantWords = () => {
    this.intolerantWords = {
      stopWords: [],
      cautionWords: [],
    };
  }

  @action resetSmsOrder = () => {
    this.smsCampaign.links = {
      link: null,
      shortLink: null,
      isShort: false,
      dynamicPartLink: null,
    };
    this.smsCampaign.shortMessage = '';
    this.smsCampaign.message = '';
  }

  @action resetOrder = () => {
    this.isNotSaveOrder = false;
    digitalBillboardsCityStreets.resetBillboards();
    this.hasBanner = true;
    this.isExistDataForBillboard = false;
    this.resolutionsFileLoaded = [];
    this.uploadFileForResolutions = {};
    this.orderFiles = [];
    this.isDelAction = false;
    this.isDelActionBillboard = false;
    this.bannerText = '';

    messageTemplatesStore.resetIntolerantWords();
    this.orderBySegment = null;
    this.order = null;
    this.digitalBillboardDetails = defaultDigitalBillboardDetails;
    this.smsCampaign = defaultSmsCampaign;
    this.pushCampaign = defaultPushCampaign;
    this.audioAdsDetails = defaultAudioAdsDetails;
    this.voiceTargetDetails = defaultVoiceTargetDetails;
    this.currentOrder = defaultCurrentOrder;
    this.otmDetails = defaultOtmDetails;
    this.calculateData = null;
    this.textEstimation = null;
    this.promoOtm = null;
    this.testPhone = '';
    messagesChainLocalStore.resetData();
    senderNameStore.resetState();
    this.isSaveToTemplate = false;
    window.localStorage.removeItem('isSaveToTemplate');
    this.smsSegmentSizeValid = '';
    customerDiscounts.resetState();
  }

  @observable size: boolean = false;

  @action isValidSizeSegment(audience: number, totalMessages: number) {
    if (audience >= totalMessages) {
      this.smsSegmentSizeValid = '';
    } else {
      this.smsSegmentSizeValid = 'Объем аудитории меньше запланированного количества. Пожалуйста, отредактируйте аудиторию, увеличив объем';
    }
  }

  @action getOrder = async (id: number, noRedirect?: boolean) => {
    try {
      this.order = await axiosBaseRequestAdTech({ url: `api/orders/${id}` });
      if (this.order?.smsCampaign?.links.dynamicPartLink) {
        // @ts-ignore
        this.order?.smsCampaign?.message = this.findText2(this.order?.smsCampaign?.links.link, this.order?.smsCampaign?.message);
        // @ts-ignore
        this.order?.smsCampaign?.shortMessage = this.findText2(this.order?.smsCampaign?.links.link, this.order?.smsCampaign?.shortMessage);
        // @ts-ignore
        this.order?.smsCampaign?.links.link = this.order?.smsCampaign?.links.link + this.order?.smsCampaign?.links.dynamicPartLink;
      }
      this.digitalBillboardDetails.bannerFiles = this.order?.digitalBillboardDetails?.bannerFiles || [];

      StoreT.linkValue.dynamicPartLink = this.order?.smsCampaign?.links.dynamicPartLink || '';
    } catch (e) {
      if (e.request?.status === 500 && !noRedirect) {
        // это чтобы быстрее понять, с какого перепуга у нас произошел редирект
        toastErrorNotification('Во время выполнения запроса произошла ошибка, попробуйте повторить операцию позднее');
        await navigate('/my-campaigns/');
      }
      console.error(e);
    }
  }

  @action getOrderBySegment = async (segmentId: number) => {
    try {
      this.orderBySegment = await axiosBaseRequestAdTech({ url: `api/segment/${segmentId}/order` });
    } catch (e) {
      console.error(e);
    }
  }

  @action getFilesByOrderId = async (orderId: number) => {
    try {
      this.orderFiles = await axiosBaseRequestAdTech({ url: `api/orders/${orderId}/files` });
    } catch (error) {
      console.error(error);
    }
  }

  removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  }

  findText = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, this.removeLastWord(url));
  }

  findText2 = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, url + this.smsCampaign.links.dynamicPartLink);
  }

  @action saveOrder = async (planBonusValue?: number) => {
    if (this.isNotSaveOrder) return;

    if (this.smsCampaign.links.dynamicPartLink) {
      // @ts-ignore
      this.smsCampaign.message = this.findText(this.smsCampaign.links.link, this.smsCampaign.message || null);
      // @ts-ignore
      this.smsCampaign.shortMessage = this.findText(this.smsCampaign.links.link, this.smsCampaign.shortMessage || null);

      // @ts-ignore
      this.smsCampaign.links?.link = this.removeLastWord(this.smsCampaign.links.link);
    }

    if (this.smsCampaign.isOtherOperators && !this.smsCampaign.otherOperators.length) {
      this.smsCampaign.otherOperators = ['Megafon', 'MTS'];
    }

    if (this.order?.status !== 'Created' && this.order?.status !== 'Draft') return;
    const campaignData = (() => {
      switch (this.order?.type) {
        case 'DigitalBillboard':
          return { digitalBillboardDetails: this.digitalBillboardDetails };
        case 'ContextAds':
          return { contextAdsDetails: this.contextAdsDetails };
        case 'Sms':
          return { smsCampaign: this.smsCampaign };
        case 'Push':
          return { pushCampaign: this.pushCampaign };
        case 'VoiceTarget':
          return { voiceTargetDetails: this.voiceTargetDetails };
        case 'Otm':
          return { otmDetails: this.otmDetails };
        case 'SocialNetwork':
          return { socialNetworkDetails: this.socialNetworkDetails };
        case 'AudioAds':
          return { audioAdsDetails: this.audioAdsDetails };
        default:
          return {};
      }
    })();

    try {
      await axiosBaseRequestAdTech({
        method: 'POST',
        url: `api/orders/${this.order?.id}`,
        data: {
          ...this.order,
          ...this.currentOrder,
          ...campaignData,
          planBonusValue:
            planBonusValue ||
            this.order?.planBonusValue && this.order.planBonusValue ||
            (customerDiscounts.planBonusValue && customerDiscounts.isClickBtnBonuses) && customerDiscounts.planBonusValue || null,
        },
      });
      if (this.order?.type === 'Sms') {
        if (newOrder?.smsCampaign?.senderId) {
          // addNameStore.getExistingFiles();
          await senderNameStore.getAllFilesBySenderId();
        }
      }
    } catch (e) {
      console.error('saveOrder', e, e?.response?.status);
      throw new Error();
    }
  }

  @action getAudience = async (id: string) => axiosBaseRequestAdTech({ url: `api/segment/${id}` })

  @action calculate = async (id: number | null = null) => {
    try {
      const request = await axiosBaseRequestAdTech({ url: `api/orders/${id || this.order?.id}/calculate` });
      this.calculateData = typeof request !== 'object' ? null : request;

      this.costBase = this.calculateData?.costs && this.calculateData?.costs[0]?.cost || 0;

      this.countBase = this.calculateData?.costs &&
      this.calculateData?.costs[0]?.count &&
      this.calculateData?.costs[0]?.count >= 6 ?
        6 :
        this.calculateData?.costs[0]?.count || 1;

      this.tooltip = this.calculateData?.costs && this.calculateData?.costs[0]?.tooltip || '';
    } catch (e) {
      console.error(e);
    }
  }

  @action getCampaignEndDate = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: `api/orders/${this.order?.id}/estimation`,
        method: 'POST',
      });
      this.textEstimation = request.text;
    } catch (e) {
      console.error(e);
    }
  }

  @action checkWordsStop = async () => {
    this.isLoadCheckWords = true;
    let currentData;
    if (this.order?.type === 'Sms') {
      currentData = this.smsCampaign.message;
    } else if (this.order?.type === 'Push') {
      currentData = this.pushCampaign.pages ? this.pushCampaign.pages.toString().replaceAll(',', ' ') : '';
    } else {
      currentData = this.smsCampaign.message;
    }
    try {
      const request = await axiosBaseRequestAdTech({
        url: 'api/orders/textCheck',
        method: 'POST',
        data: { text: currentData },
      });
      this.intolerantWords = {
        stopWords: request.stopWords,
        cautionWords: request.cautionWords,
      };
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadCheckWords = false;
    }
  }

  @action startCampaign = async () => {
    if (!this.order?.id) return;
    await axiosBaseRequestAdTech({ url: `api/orders/${this.order?.id}/start` });
  }

  @action setDraft = async (id: number, isUpdateOrder?: boolean) => {
    await axiosBaseRequestAdTech({ url: `api/orders/${id}/draft` });
    if (isUpdateOrder) {
      await this.getOrder(id);
    }
  }

  @action getAudioAdsTariffs = async () => {
    try {
      this.audioAdsTariffs = await axiosBaseRequestAdTech({ url: '/api/orders/audioAds/tariffs' });
    } catch (error) {
      console.error(error);
    }
  }
  // @ts-ignore
  private getBan = (): string => this.currentOrder?.ban ? this.currentOrder?.ban : UserInfo?.data?.company?.ban;

  private setDefaultNameFirst = (arr: any) => {
    const filteredArr = arr.filter((item: any) => item.senderName === 'BrandInfo');
    return filteredArr.concat(arr.filter((item: any) => item.senderName !== 'BrandInfo'));
  };

  @action checkForNameUsage = async () => {
    try {
      this.isLoadingData = true;
      const { data } = await axiosBaseRequestAdTech({ url: `api/senderNames?skip=0&take=777&ban=${this.getBan()}` });
      if (NewAudiences.typeAudience === 'MultiOperators') {
        this.nameList = this.setDefaultNameFirst(data.filter((el: any) => el.isMultiOperator));
      } else {
        this.nameList = this.setDefaultNameFirst(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingData = false;
    }
  }

  @computed get totalPrice() {
    const discount = this.calculateData?.discountPrice && this.calculateData?.discountPrice > 0 ? this.calculateData?.discountPrice : 0;
    return this.calculateData?.discount !== 0 ?
      discount :
      this.calculateData?.price;
  }

  @computed get lengthSms() {
    if (this.smsCampaign.links.isShort && this.smsCampaign.shortMessage && this.smsCampaign.isParameterized) {
      return replaceVariables(this.smsCampaign.shortMessage || '', StoreT.variables).length - ((this.smsCampaign.links.shortLink?.length || 22) + StoreT.promo.length);
    }
    if (this.smsCampaign.links.isShort && this.smsCampaign.shortMessage && !this.smsCampaign.isParameterized) {
      return replaceVariables(this.smsCampaign.shortMessage || '', StoreT.variables).length - (this.smsCampaign.links.shortLink?.length || 22);
    }
    if (!this.smsCampaign.links.isShort && this.smsCampaign.shortMessage && this.smsCampaign.isParameterized) {
      return replaceVariables(this.smsCampaign.shortMessage || '', StoreT.variables).length - StoreT.promo.length;
    }
    if (this.smsCampaign.shortMessage) {
      const isDynamicLink = !!this.smsCampaign.links.dynamicPartLink;
      return replaceVariables(isDynamicLink ? this.smsCampaign.shortMessage.replace(/\/[^/]+$/, '/') + this.smsCampaign.links.dynamicPartLink : this.smsCampaign.shortMessage || '', StoreT.variables).length;
    }
    return 0;
  }

  @computed get totalBudget() {
    return this.calculateData?.discount !== 0 ?
      this.calculateData?.discountBudget :
      this.calculateData?.budget;
  }

  @computed get countMessage() {
    return this.calculateData?.count || 0;
  }

  @computed get countMessageOther() {
    return this.calculateData?.otherOperatorsCount || 0;
  }

  @computed get countMessagePush() {
    return this.calculateData && this.calculateData?.count ?
      this.calculateData?.count : 0;
  }
}

const newOrder = new NewOrder();

export default newOrder;

reaction(
  () => NewAudiences.typeAudience,
  () => {
    newOrder.nameList = [];
  },
);

reaction(
  () => newOrder.order,
  () => {
    if (newOrder.order && newOrder.isDraft) {
      newOrder.currentOrder = {
        audience: newOrder.order?.audience,
        planPrice: newOrder.order?.planPrice,
        planMessages: newOrder.order?.planMessages,
        segmentId: newOrder.order?.segmentId,
        startDate: newOrder.order?.startDate,
        endDate: newOrder.order?.endDate,
        name: newOrder.order?.name,
        comment: newOrder.order?.comment,
        ban: newOrder.order?.ban,
        industryId: newOrder.order?.industryId,
        inn: newOrder.order?.inn,
        company: newOrder.order?.company,
        customerId: newOrder.order?.customerId,
        invoiceId: newOrder.order?.invoiceId,
        isSavedAudience: newOrder.order?.isSavedAudience,
      };
    }

    if (newOrder.order?.smsCampaign) {
      newOrder.smsCampaign = newOrder.order?.smsCampaign;
    }
    if (newOrder.order?.pushCampaign) {
      newOrder.pushCampaign = newOrder.order?.pushCampaign;
    }
    if (newOrder.order?.voiceTargetDetails) {
      newOrder.voiceTargetDetails = newOrder.order?.voiceTargetDetails;
      // https://btask.beeline.ru/browse/TSMS-5936
      newOrder.voiceTargetDetails = {
        audienceType: newOrder.order?.voiceTargetDetails?.audienceType || 'NotTarget',
        industryId: newOrder.order?.voiceTargetDetails?.industryId,
        numberType: newOrder.order?.voiceTargetDetails?.numberType || 'Mobile',
      };
    }
    if (newOrder.order?.otmDetails && newOrder.isDraft) {
      newOrder.otmDetails = newOrder.order?.otmDetails;
    }
    if (newOrder.order?.audioAdsDetails) {
      newOrder.audioAdsDetails = newOrder.order?.audioAdsDetails;
    }
    // if (newOrder.order?.digitalBillboardDetails && newOrder.isDraft) {
    //   newOrder.digitalBillboardDetails = newOrder.order?.digitalBillboardDetails;
    // }
  },
);
