import React, { useState, useMemo, useEffect } from 'react';
// @ts-ignore
import { useLocation, useNavigate } from '@reach/router';
// import { Preloader } from 'components/common/index';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Preloader from 'redesignSrc/UI/loaders/Preloader';
import UserInfo from 'store/mobx/UserInfo';
import authorizationStore from 'store/mobx/Authorization';
import Login from 'pages/Authorization/containers/Login';
import { isNullOrUndefined } from 'utils/fn';

type Props = {
  PageBeePro: JSX.Element,
  PageTsms: JSX.Element,
  PageAggregator: JSX.Element,
}


const AppUserAccessInitializer: React.FC<Props> = ({ PageBeePro, PageTsms, PageAggregator }) => {
  const [option, setOption] = useState<null | 0 | 1 | 2 | undefined>(null);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Костыль для блокировки чата по бану
    const isNotChatForBan = UserInfo.data?.company.ban === '856428295';

    if (!isNullOrUndefined(option) && option !== 0 && !UserInfo.isAggregator && !isNotChatForBan) {
      // Подключаем скрипт чата
      (function () {
        // @ts-ignore
        if (window.carrotquest) return;

        const script = document.createElement('script');
        script.src = 'https://cdn.carrotquest.app/api.min.js';
        script.async = true;
        document.head.appendChild(script);

        // @ts-ignore
        window.carrotquest = {};
        // @ts-ignore
        window.carrotquestasync = [];

        ['connect', 'track', 'identify', 'auth', 'onReady', 'addCallback', 'removeCallback', 'trackMessageInteraction'].forEach((method) => {
          // @ts-ignore
          window.carrotquest[method] = function () {
            // @ts-ignore
            // eslint-disable-next-line prefer-rest-params
            window.carrotquestasync.push(method, arguments);
          };
        });
        // @ts-ignore
        window.carrotquest.connect('50212-e2a7f1253d19c311627c4f91b1');
      }());
    }
  }, [option]);

  useMemo(async () => {
    const dataSource = search.includes('source_resource') ? search : '';
    await UserInfo.getUser(false, dataSource);
    window.ym(87718585, 'params', { USERID: `n${UserInfo.data?.id}` });

    if (search.includes('source_resource')) {
      navigate(`${pathname}`);
    }
    setOption(UserInfo.data?.appType);
    // Типо партнер локально
    // setOption(0);

    // @ts-ignore
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: 'gtm.js' });
    // в скрипте Гугл было так: w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }, []);


  if (!option && !authorizationStore.isLoggedIn) {
    // если юзер не залогинен, то шлём его на страницу логина
    return <Login/>;
  }

  if (UserInfo.isAggregator) {
    return PageAggregator;
  }

  switch (option) {
    case 0:
      return PageTsms;
    case 1:
    case 2:
      return PageBeePro;
    default:
      return <div style={{ height: '500px' }}><Preloader/></div>;
  }
};

export default observer(AppUserAccessInitializer);
