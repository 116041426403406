import React from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { Button, Icon } from '@beeline/design-system-react';
import { observer } from 'mobx-react';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import sideSheetStore from 'store/mobx/SideSheetStore';
import { questionsState } from '../../store';
import styles from './styles.pcss';

const FirstScreen = observer(() => {
  if (!questionsState.surveys?.surveyId || questionsState?.status === 'Finished') return null;
  return (
    <div className={styles.holder} style={{
      backgroundImage: `url(${questionsState?.surveys?.initialImageLink})`,
      backgroundSize: '60%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '231px 36px',
    }}>
      <Button
        endIcon={<Icon iconName={Icons.Close} />}
        size="small"
        className={styles.closeBtn}
        onClick={() => {
          questionsState.surveys = null;
        }}
      />
      <TypographyWithDangling variant="subtitle1" className={styles.surveyActionTitle}>{questionsState?.surveys?.description}</TypographyWithDangling>
      <TypographyWithDangling
        variant="body2"
        className={styles.description}
      >
        {questionsState?.surveys?.initialWindowText}
      </TypographyWithDangling>
      <Button
        className={styles.actionBtn}
        onClick={() => {
          if (questionsState.currentScreen === 0) {
            questionsState.currentScreen = 1;
          }
          questionsState.isOpened = true;
          sideSheetStore.componentToShow = 'polls';
        }}
      >
        Пройти опрос
      </Button>
    </div>
  );
});

export default FirstScreen;
