import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { PureButton } from 'redesignSrc/UI/buttons';
import { formatAmount, formatPriceTotal } from 'utils/formatting';
import styles from './styles.pcss';
import Wallet from './assets/wallet.svg';
import Scores from './assets/scores.svg';

type Props = {
  handleWallet:() => void
  balance?: number | null
  score: number | null;
  handleScore: () => void
}

const WalletButton:React.FC<Props> = ({ handleWallet, balance, score, handleScore }) => {
  const [isNegative, setIsNegative] = useState(false);

  useEffect(() => {
    if (balance) {
      setIsNegative(balance < 0);
    }
  }, []);

  return (
    <div className={styles.balance}>
      <PureButton
        onClick={handleWallet}
        className={styles.btn}
    >
        <Wallet/>
        <span className={classNames(styles.balanceNumber, isNegative && styles.balanceNegative)}>{formatPriceTotal(balance)}</span>
      </PureButton>
      <div className={styles.divider} />
      <PureButton onClick={handleScore} className={styles.btn}>
        <Scores />
        <span className={classNames(styles.balanceNumber, isNegative && styles.balanceNegative)}>{formatAmount(score)}</span>
      </PureButton>
    </div>
  );
};

export default observer(WalletButton);
