import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import withError from 'redesignSrc/HOC/withError';

import {
  SENDER_NAME_STATUSES,
  senderNameStore,
} from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import NewOrder from 'store/mobx/NewOrder';
import sideSheetStore from '../../../../store/mobx/SideSheetStore';
import Arrow from './arrow.svg';
import styles from './styles.pcss';

type Props = {
  children?: React.ReactNode | string, // компонент списока элементов
  value?: string, // Сверху кидаем что выбрали из списка
  className?: string,
  contentClassName?: string,
  status?: string,
  marginTop?: number,
  marginBottom?: number,
  isClose?: boolean,
  isManyChoicesType?: boolean,
  placeholder?: string
  disabled?: boolean
  classNameContainer?: string,
  id?: string,
  isError?: string,
  name?: string
}

const SelectionInput: React.FC<Props> = ({
  status,
  children,
  value,
  className,
  contentClassName,
  isManyChoicesType,
  placeholder,
  disabled,
  isClose,
  classNameContainer,
  id,
  isError,
  name,
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (isClose) {
      setActive(false);
    }
  }, [isClose]);
  return (
    <div>
      {/* {labelText && <FormFieldLabel>{labelText}</FormFieldLabel>} */}

      <div id={id || name} className={classNames(styles.wrapper, className)}>
        <div
          id="input"
          onClick={() => {
            if (!disabled) {
              setActive(!active);
            }
          }
          }
          className={classNames(
            styles.container,
            classNameContainer,
            status === 'fail' && styles.fail,
            active && styles.focusBorder,
            disabled && styles.disabled,
            isError && styles.fail,
          )}>
          {value ? <span id="item" className={styles.text}>{value}</span> :
          <span id="placeholder2" className={styles.defaultText}>{placeholder || 'Не выбран'}</span>}

          <Arrow className={`${styles.arrow} ${active ? styles.rotated : ''}`}/>
        </div>

        {active && (
          <div
            onClick={isManyChoicesType ? undefined : () => setActive(false)}
            className={classNames(styles.containerSelect, contentClassName, active && styles.focusBorder)}
            id="containerSelect"
          >
            <OutsideClickTracker onClickOutside={(e: any) => {
              e.stopPropagation();
              if (e.target.id !== 'containerSelect' && e.target.id !== 'input' && e.target.id !== 'placeholder' && e.target.id !== 'placeholder2' && e.target.id !== 'item') {
                setActive(false);
              }
            }
            }>
              {children}
            </OutsideClickTracker>
          </div>
        )}
      </div>
    </div>
  );
};

type SProps = {
  children?: React.ReactNode | string, // компонент списока элементов
  value?: string | null, // Сверху кидаем что выбрали из списка
  className?: string,
  classNameContainer?: string,
  labelText?: string,
  marginTop?: number,
  marginBottom?: number,
  isOpen?: boolean,
  active: boolean,
  setActive: (isActive: boolean) => void,
  error?: string,
  id?: string,
  placeholder?: string
  isEditActive?: boolean
  valueId?: number
  status?: string
  isSenderName?: boolean;
  name?: string,
}

const SelectionInputStateless: React.FC<SProps> = withError(observer(({
  children,
  value,
  labelText,
  className,
  active,
  setActive,
  error,
  id,
  placeholder,
  isEditActive,
  valueId,
  isSenderName,
  classNameContainer,
  name,
}) => {
  useEffect(() => {
    if (!NewOrder.smsCampaign.senderId) return;
    (async () => {
      await senderNameStore.getSenderNameById();
    })();
  }, [valueId, senderNameStore.senderStatus]);
  // eslint-disable-next-line no-shadow
  const onEditNameHandler = (id: number | undefined) => (e: any) => {
    // if (e.target.dataset.edit === 'edit') {
    //   senderNameStore.showEditSenderNameModal = true;
    //   senderNameStore.editSenderNameId = id || null;
    // }
    sideSheetStore.componentToShow = 'senderName';
    sideSheetStore.sideSheetComponentProps = { id };
  };

  const renderOption = () => {
    const isEditable = (senderNameStore.senderStatus === 'Rejected' || senderNameStore.senderStatus === 'Draft') && NewOrder.smsCampaign.senderName !== 'BrandInfo';
    return (
      <>
        {isEditable ? <span>
          <span data-edit="edit" className={classNames('editSenderName', styles.editSenderName, styles.editLinkColor)}
            onClick={onEditNameHandler(valueId)}>Редактировать имя</span>
        </span> : null}
      </>
    );
  };

  const setActiveHandler = () => (e: any) => {
    if (e.target.dataset.edit === 'edit') {
      return;
    }
    setActive(!active);
  };

  const buildStatus = (status: string, moderatedTill: string | null): string | JSX.Element => {
    if (status !== 'Accepted') {
      return <span className={styles[status]}>({SENDER_NAME_STATUSES[status]?.toLowerCase()})</span>;
    }
    return moderatedTill ?
      <span>(<span className={styles.green}>Действует для билайна до {moment(moderatedTill).format('DD.MM.YYYY')}</span>)</span> : '(Действует)';
  };

  return (
    <div>
      {labelText && <FormFieldLabel>{labelText}</FormFieldLabel>}

      <div className={classNames(styles.wrapper, className)} id={id || name}>
        <div onClick={setActiveHandler()}
          className={classNames(styles.container, classNameContainer, error && styles.error, active && styles.focusBorder)}>
          {value ? <span className={styles.text}>
            {value} {isSenderName ? buildStatus(senderNameStore.senderStatus, senderNameStore.moderatedTill) : null} {isEditActive ? renderOption() : null}
          </span> : <span id="placeholder" className={styles.defaultText}>{placeholder || 'Не выбран'}</span>}
          <Arrow className={classNames(styles.arrow, active && styles.rotated)}/>
        </div>
        {active && <div className={classNames(styles.containerSelect)}>{children}</div>}
      </div>
    </div>
  );
}));

export { SelectionInputStateless };
export default withError(SelectionInput);
