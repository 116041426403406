import React, { useMemo } from 'react';

import classNames from 'classnames';
import styles from './styles.pcss';


type Props = {
  data: { cost: number, discountValue: number }[]
  currentCost?: number
}

const MAX_HEIGHT = 152;

const DiscountPlanChart: React.FC<Props> = ({ data, currentCost = 0 }) => {
  const stepHeight = Math.round(MAX_HEIGHT / (data.length));
  const currentData = [{ cost: 0, discountValue: null }, ...data];
  const priceNext = useMemo(() => {
    if (data) {
      return data.find(el => el.cost > currentCost) || null;
    }
    return null;
  }, [currentCost, data]);


  if (!data) return null;


  return (
    <div className={styles.containerChart}>
      {priceNext &&
        <div className={styles.info}>
          {`До скидки ${priceNext?.discountValue}% осталось отправить рассылок на\u00A0${priceNext.cost - currentCost}\u00A0руб.`}
        </div>
      }
      <div className={styles.containerBar}>
        {currentData.map(((el, i) => {
          const heightBar = (MAX_HEIGHT - stepHeight * (data.length - i));
          const isActiveDiscount = currentCost >= el.cost;

          return (
            <div className={styles.wrapBar}>
              {el.discountValue && <span className={styles.discount}>{`${el.discountValue}%`}</span>}
              <div style={{ height: `${heightBar}px` }}
                className={classNames(styles.bar, isActiveDiscount && styles.activeBar)}/>
              {el.cost && <span>{el.cost === 0 ? 'Старт' : el.cost}</span>}
            </div>
          );
        }))}
      </div>


      <div className={styles.axisX}>
        <div className={styles.divider}/>
      </div>
      <div/>

    </div>
  );
};
export default DiscountPlanChart;
