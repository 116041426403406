import React, { useRef, useEffect, useState } from 'react';
import { useLocalStore, useObserver, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { useLocation, useNavigate } from '@reach/router';
import Authorization from 'store/mobx/Authorization';
import { OverlayLoader } from 'components/common/Loaders/components';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import { OFFER_ACCEPTED_DATE } from 'store/common/userInfo/constants';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { composeEmailValidator } from 'utils/fieldValidators';
import { ym } from 'utils/ga-analytics/utils';
import UserInfo from 'store/mobx/UserInfo';
import { NEW_CAMPAIGN_URL } from 'pages/constants';
import { Logo, NewLogo } from '../assets';
import styles from './styles.pcss';

const TOTAL_INPUTS = 3;

const Sms = () => {
  const { phone, setScreen } = Authorization;
  const goBack = () => setScreen('phone');
  const [isVisible, setIsVisible] = useState(false);
  const { search, pathname } = useLocation();
  const [email, setEmail] = useState(Authorization.email || null);
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const onEmailChange = (e) => {
    e.currentTarget.value;
    setEmail(e.currentTarget.value);
  };

  useEffect(() => {
    if (Authorization.showEmail) {
      ym(87718585, 'reachGoal', 'form_shown_email_new');
      console.info('ID:324921365-YM');
    }
  }, [Authorization.showEmail]);

  const validateEmail = () => {
    const error = composeEmailValidator(
      'Email должен быть заполнен в формате XXXX@XXX.XX',
    )(email);

    if (error) {
      setEmailError(error);
      return false;
    }
    setEmailError('');

    return true;
  };

  if (search.includes('source_resource')) {
    navigate(`${pathname}`);
  }

  const fields = {};
  fields[0] = useRef();
  fields[1] = useRef();
  fields[2] = useRef();
  fields[3] = useRef();
  useEffect(() => {
    const onBodyClick = (e) => {
      if (e.target.id !== 'sms_text') {
        setIsVisible(false);
      }
    };
    if (isVisible) {
      document.body.addEventListener('click', onBodyClick);
    } else {
      document.body.removeEventListener('click', onBodyClick);
    }
    return () => document.body.removeEventListener('click', onBodyClick);
  }, [isVisible]);
  const state = useLocalStore(() => ({
    code: {
      0: '',
      1: '',
      2: '',
      3: '',
    },
    isFieldsDisabled: false,
    keyCode: null,
    codeVerifying: false,
    onKeyDown: (e, index, value) => {
      if (isNaN(value)) return;
      state.keyCode = e.keyCode;
      if (state.keyCode === 8 && state?.code[index - 1] && state?.code[index - 1].length && !state?.code[index]?.length) {
        state.code[index - 1] = '';
        fields[index - 1].current.focus();
      }
    },

    inputSmsCode: (e, index, value) => {
      if (isNaN(value)) return;
      state.isError = false;
      if (state.keyCode === 8 && state?.code[index]?.length) {
        state.code[index] = '';
        return;
      }

      if (state?.code[index]?.length && !state?.code[index + 1]?.length) {
        if (index < TOTAL_INPUTS && state.code[index + 1]?.length) {
          fields[`${index + 1}`].current.focus();
          state.code[index + 1] = value;
        }
      } else {
        state.code[index] = value;
        if (state.code[index].length) {
          fields[`${index + 1}`] && fields[`${index + 1}`].current.focus();
        }
      }
    },
    isFocused: true,
    isFirstTimeInputed: false,
    verifyCode: async () => {
      if (Object.values(state.code).join('').length !== 4) return;
      state.codeVerifying = true;
      try {
        const response = await Authorization.phoneConfirmation(
          { phone: phone.replace(/\D/g, ''), code: Object.values(state.code).join('') },
        );
        if (response?.message !== 'success') {
          ym(87718585, 'reachGoal', 'code_sent_error');
          console.info('ID:231445305-YM');
        }
        if (response?.message === 'success') {
          ym(87718585, 'reachGoal', 'code_sent_success');
          console.info('ID:231445305-YM');
        }

        if (response?.response?.status === 401 || response?.response?.status === 429) {
          return;
        }
        const script = document.createElement('script');
        script.src = 'https://ce517835f3484a93b98aae80c3ad001f.ops.beeline.ru';
        script.async = true;
        document.body.appendChild(script);
        window.location.href = pathname;
      } catch (e) {
        if (e.response?.status === 400) {
          state.isError = true;
        }
        state.code = {
          0: '',
          1: '',
          2: '',
          3: '',
        };
        fields[0]?.current?.focus();
        ym(87718585, 'reachGoal', 'code_sent_error');
        console.info('ID:231445305-YM');
      } finally {
        state.codeVerifying = false;
      }
    },
    sendPhone: async () => {
      await Authorization.phoneConfirmation({ phone: phone.replace(/\D/g, '') });
      Authorization.isError429 = false;
    },
    isError: false,
  }));

  const handleSubmit = async () => {
    if (validateEmail()) {
      try {
        state.codeVerifying = true;
        await Authorization.registration({
          phone: phone.replace(/\D/g, ''),
          code: Object.values(state.code).join(''),
          email,
        });
        await UserInfo.getUser(true);
        await axiosBaseRequestAdTech({
          url: '/api/Customers/offerAccepted',
          method: 'POST',
          data: {
            email: UserInfo.data.email,
            userId: UserInfo.data.id,
          },
        });

        ym(87718585, 'reachGoal', 'auth_success');
        console.info('ID:324387044-YM');

        const script = document.createElement('script');
        script.src = 'https://ce517835f3484a93b98aae80c3ad001f.ops.beeline.ru';
        script.async = true;
        document.body.appendChild(script);

        window.location.href = pathname;
      } catch (e) {
        console.error(e);
      } finally {
        state.codeVerifying = false;
      }
    }
  };

  useEffect(() => {
    fields[0].current.focus();
    reaction(
      () => {
        return Object.values(state.code).every(el => el.length);
      },
      () => {
        state.verifyCode();
      },
    );
  }, []);

  useEffect(() => {
    state.isFieldsDisabled = false;
  }, []);

  useEffect(() => {
    const sendCode = setInterval(() => {
      if (Authorization.timeLeft) {
        Authorization.timeLeft -= 1;
      }
    }, 1000);
    if (Authorization.timeLeft === 0) clearInterval(sendCode);
    return () => {
      clearInterval(sendCode);
    };
  }, []);

  const onPaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('Text');
    if (text.length === 4 && text.replace(/\D/g, '').length === 4) {
      text.split('').forEach((number, idx) => {
        state.code[idx] = number;
      });
    }
  };


  return useObserver(() => (
    <>
      <div className={styles.logoSms}>
        <NewLogo/>
      </div>
      <div className={styles.descriptionCode}>
        {Authorization.showEmail ? 'Введите Ваш e-mail' : 'Введите код из SMS'}
      </div>
      <div className={styles.smsContainer}>
        {!Authorization.showEmail && <OverlayLoader classNamePreload={styles.loader} isLoading={state.codeVerifying}>
          <>
            <p className={styles.title}>
              Мы отправили код на {phone}
              <span className={styles.numberChange} onClick={goBack}>Изменить</span>
            </p>
            <div className={styles.wrapInput}>
              {Object.values(state.code).map((field, index) => {
                return (
                  <input
                    className={`${styles.field} ${state.isError ? styles.smsCodeError : ''}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    disabled={state.isFieldsDisabled}
                    ref={fields[index]}
                    type="tel"
                    onPaste={onPaste}
                    onKeyDown={(e) => state.onKeyDown(e, index, e.currentTarget.value)}
                    onChange={(e) => state.inputSmsCode(e, index, e.currentTarget.value)}
                    value={state.code[index]}
                  />
                );
              })
              }
            </div>


            {
              (state.isError && !Authorization.isError429) &&
              <div className={styles.errorTextMsg}>Неверный код</div>
            }

            {
              Authorization.isError429 && <div className={styles.errorTextMsg}>
                {Authorization.textError429}
              </div>
            }

          </>

        </OverlayLoader>}

        {(Authorization.showEmail && (
        <OverlayLoader classNamePreload={styles.loader} isLoading={state.codeVerifying}>
          <input
            placeholder="e-mail"
            type="email"
            onChange={onEmailChange}
            className={`${styles.emailInput} ${
                  emailError ? styles.errorInput : ''
            }`}
            value={email}
            onBlur={validateEmail}
              />
          {emailError && (<label className={styles.errorTextMsg}>{emailError}</label>)}
          <PrimaryButton
            className={styles.button}
            onClick={handleSubmit}
            isDisabled={state.codeVerifying}
              >
            Продолжить
          </PrimaryButton>

        </OverlayLoader>
          )) ||
          null}

        {!Authorization.showEmail &&
          <div className={styles.termsHolder}>
            <div className={`${styles.row} ${styles.termMargin}`}>
              {
                Authorization.timeLeft &&
                <span className={styles.text}>Получить новый SMS-код можно через {Authorization.timeLeft}</span> ||
                <span className={styles.sendCode} onClick={state.sendPhone}>Получить новый SMS-код</span>
              }
            </div>

            <div className={`${styles.row} ${styles.smsRow}`}>
              <span className={styles.cantGetText} onClick={() => setIsVisible(!isVisible)}
                id="sms_text">Не приходит SMS?</span>
              <div className={`${styles.bubble} ${isVisible ? styles.showBubble : ''}`}>
                <div className={styles.cantGetSmsRow}>
                  <span className={styles.cantGetSmsNumber}>1.</span>
                  Проверьте правильность заполненного номера телефона
                </div>
                <div className={styles.cantGetSmsRow}>
                  <span className={styles.cantGetSmsNumber}>2.</span>
                  Позвоните по бесплатному номеру <br/> <a href="tel:88006006262" className={styles.link}>8 (800)
                    600-62-62</a>
                </div>
                <div className={styles.cantGetSmsRow}>
                  <span className={styles.cantGetSmsNumber}>3.</span>
                  Проблемы могут быть со стороны вашего сотового оператора, нужно обратиться в их службу поддержки
                </div>
              </div>
            </div>
          </div>
        }

      </div>
    </>
  ));
};

export default observer(Sms);
