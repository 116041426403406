import { action, observable, computed, runInAction, toJS } from 'mobx';
// @ts-ignore
import debounce from 'debounce';
// @ts-ignore
import qs from 'query-string';
import { axiosBaseRequestAdTech } from '../../requests/helpers';
import { StatusType } from './NewOrder/type';

type CampaignFilters = {
  id: number,
  label: string,
}

export type TsmsOrder = {
  type: string,
  id: number,
  name: string,
  status: StatusType,
  startDate: Date,
  endDate: Date,
  planPrice?: number,
  totalPrice: number,
  discountPrice: number,
  invoicePrice: number,
  invoiceStatus: 1 | 2 | 3 | null,
  invoiceName: string | null,
  company: string,
  ban: string,
  eventCount: number
  sentMessagesCount: number,
  invoiceId: number | null,
  creator: string
  isAutoInvoice: boolean,
  industryId: number | null,
  customerId: number | null,
  unseenStopDemandsId: [] | null,
}

export interface ICampaignParams {
  pageCount: number,
  orders: [] | TsmsOrder[]
}

export type Filter = {
  statuses: Array<number>,
  search: string,
  types: Array<number>,
  startDate?: string,
  endDate?: string,
  page: number,
};

class MyCampaigns {
  @observable isLoading = false;
  @observable campaigns: TsmsOrder[] = [];
  @observable defaultText = 'Ничего не найдено'
  @observable noMoreCampaigns = false;
  @observable campaignsStatuses: Array<CampaignFilters> = [];
  @observable campaignsTypes: Array<CampaignFilters> = [];
  @observable totalCampaignsCount: number = 0;
  @observable redirectToCampaign = true;
  @observable filter: Filter = {
    statuses: [],
    page: 1,
    search: '',
    types: [],
    startDate: undefined,
    endDate: undefined,
  };
  @action resetFilters = () => {
    this.redirectToCampaign = false;
    this.nextPageUrl = `/api/orders${this.createSearchUrl()}`;
    this.filter = {
      statuses: [],
      search: '',
      page: 1,
      types: [],
      startDate: undefined,
      endDate: undefined,
    };
  }


  createSearchUrl = () => {
    return `?sortBy=id&orderBy=desc&${qs.stringify(toJS(this.filter), { skipEmptyString: true })}`;
  }

  @observable nextPageUrl?: string | null = `/api/orders${this.createSearchUrl()}`;

  resetPages = () => {
    this.campaigns = [];
    this.nextPageUrl = `/api/orders${this.createSearchUrl()}`;
    this.filter.page = 1;
  }

  @action getCampaignByParams = async () => {
    this.isLoading = true;
    try {
      const { orders, pageCount }: ICampaignParams = await axiosBaseRequestAdTech({
        url: `/api/orders${this.createSearchUrl()}`,
      });
      runInAction(() => {
        if (this.filter.page === 1) {
          this.campaigns = orders.filter(campaign => campaign.type !== 'Focus');
        } else {
          this.campaigns = [...this.campaigns, ...orders.filter(campaign => campaign.type !== 'Focus')];
        }
        this.totalCampaignsCount = pageCount;
        if (!orders.length) this.nextPageUrl = null;
        this.defaultText = 'Создайте вашу первую рекламную кампанию, нажав на кнопку «Создать кампанию»';
      });
    } catch (e) {
      this.defaultText = 'Ничего не найдено';
      this.nextPageUrl = null;
    } finally {
      this.isLoading = false;
    }
  }

  getMoreCampaigns = async () => {
    if (this.filter.page <= this.totalCampaignsCount) {
      this.filter.page += 1;
      await this.getCampaignByParams();
    }
  }

  // @action syncAddMyCampaigns = async () => {
  //   this.isLoading = true;
  //   const { statuses, ...otherParams } = this.filter;
  //
  //   const preparedParams = {
  //     ...otherParams,
  //     ...(status ? getPreparedFilterStatusParams(status) : {}),
  //   };
  //
  //   const currentOffset = this.campaigns.length;
  //   try {
  //     const { results, next } = await getCampaings(filterValuable(preparedParams), currentOffset);
  //     runInAction(() => {
  //       this.campaigns = this.campaigns.concat(results);
  //       this.nextPageUrl = next;
  //     });
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

  @computed get hasNextPageData() {
    return this.nextPageUrl !== null;
  }
}

const myCampaigns = new MyCampaigns();

export default myCampaigns;
