import React from 'react';
import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableHeaderData, TableBody, TableData,
} from '@beeline/design-system-react';

import { StatisticBanType } from 'redesignSrc/pages/NewAudience/components/CheckoutModal/requests';
import styles from './styles.pcss';


type Props = {
  data: StatisticBanType[]
}

const TableStatistic: React.FC<Props> = ({ data }) => {
  return (
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableHeaderData>
            Месяц
          </TableHeaderData>
          <TableHeaderData>
            Кол-во кампаний
          </TableHeaderData>
          <TableHeaderData>
            Кол-во сообщений
          </TableHeaderData>
          <TableHeaderData>
            Сумма
          </TableHeaderData>
          <TableHeaderData>
            Скидка
          </TableHeaderData>
        </TableRow>
      </TableHead>
      <React.Fragment/>
      <TableBody>
        {data.map((item, i) => {
          return (
            <TableRow>
              <TableData>{item.period}</TableData>
              <TableData>{item.campaignsCount}</TableData>
              <TableData>{item.messagesCount}</TableData>
              <TableData>{item.priceSum}</TableData>
              <TableData>{item.discount || 0}</TableData>
            </TableRow>
          );
        })}
        {/* <TableRow> */}
        {/*  <TableData */}
        {/*    colSpan={7} */}
        {/*    style={{ */}
        {/*      fontSize: 'unset', */}
        {/*    }} */}
        {/*  > */}
        {/*    <TablePagination */}
        {/*      rowsCount={data.length} */}
        {/*      page={1} */}
        {/*      rowsPerPage={5} */}
        {/*    /> */}
        {/*  </TableData> */}
        {/* </TableRow> */}
      </TableBody>
    </Table>


  );
};

export default TableStatistic;
