import { action, observable, reaction, computed, toJS } from 'mobx';
import { format } from 'date-fns';
import { toastErrorNotification, toastAccessNotification } from 'modules/toast-notifications';
import { adTechRequest, axiosAuthorizedRequest, axiosBaseRequestAdTech } from '../../requests/helpers';
import { formatOffer } from '../../utils/date';
import UserInfo from './UserInfo';

type TypesendInvoiceData = {
  email:string,
  invoice_number: number | null,
  type: 1,
}

type CreateBillArgs = {
  amount: number,
  email?: string | null,
  order_id?: string,
}

export type OperationListItem = {
  date: string,
  type: string,
  type_name: string,
  amount: number,
  campaign_id?: string,
  channel?: string,
  campaign_name?: string | null,
  order_id?: number
  orderName?: string | null
  orderIdTsms?: number | null,
  is_sbp?: boolean,
}

export type InvoiceListItem = {
  amount: number
  create_date: string
  invoice_number: number
  is_sbp: boolean,
}

type TypeFilterHistory = {
  from: string | null,
  to: string | null,
  type: keyof typeof MapTypeHistoryFilter,
  page: number,
  loading: boolean,
}

type TypeFilterInvoces = {
  page: number,
  from: string | null,
  to: string | null,
  search: string,
  loading: boolean,
}

interface PaginationItems {
  count: number|null,
  next: string|null,
  previous: string|null,
  current_page:number|null,
  total_pages: number|null,
  result: any,
}

export type TypeBalance = {
  summary_balance: null | number,
  free_balance: null | number,
  reserved_balance: null | number,
  reserved_details: null | any[],
}

interface InvoiceList extends PaginationItems { result: []|InvoiceListItem[] }

export const MapTypeHistoryFilter = {
  all: ['payment', 'wrong_payment', 'charge', 'return'],
  addition: ['payment'],
  debit: ['charge'],
  return: ['return'],
};

export const sendGA = (
  eventContent: 'Скачать' | 'Отправить' | 'Отправить_err' | 'Сохранить' | 'Скачать_err' | 'Сохранить_err',
  blockName: 'balance' | 'check',
) => {
  // const dataLayer = window.dataLayer || [];
  // dataLayer.push({
  //   event: 'event_b2b_beePro',
  //   eventCategory: 'Interaction',
  //   eventAction: 'click',
  //   eventLabel: 'button',
  //   eventContent,
  //   eventOther: 'null',
  //   blockName,
  // });
};

type PaginationListOperations<T> = {
  totalCount: null,
  operations: T,
}

class Wallet {
  @observable balance:TypeBalance = {
    summary_balance: null,
    free_balance: null,
    reserved_balance: null,
    reserved_details: null,
  }
  @observable campaigns: any = null
  @observable operation_list:PaginationListOperations<OperationListItem[]|[]> = {
    totalCount: null,
    operations: [],
  }
  @observable invoices:InvoiceList = {
    count: null,
    next: null,
    previous: null,
    current_page: null,
    total_pages: null,
    result: [],
  };

  @observable payments:InvoiceList = {
    count: null,
    next: null,
    previous: null,
    current_page: null,
    total_pages: null,
    result: [],
  };

  @observable sendInvoiceData:TypesendInvoiceData = {
    email: UserInfo?.data?.email as string,
    invoice_number: null,
    type: 1,
  }

  @observable filterHistory:TypeFilterHistory = {
    page: 1,
    from: null,
    to: null,
    type: 'all',
    loading: false,
  }

  @observable filterInvoice:TypeFilterInvoces = {
    page: 1,
    from: null,
    to: null,
    search: '',
    loading: false,
  }

  @observable searchString = '';
  @observable prevCloseDocsParams = '';

  @action sendClosingDocs = (email:string, invoice_number:boolean|number) => {
    try {
      axiosBaseRequestAdTech({
        url: '/api/Customers/reprint',
        method: 'POST',
        data: {
          email,
          invoiceNumber: invoice_number,
          type: 0,
        },
      });
      sendGA('Отправить', 'check');
      toastAccessNotification(`Счёт успешно сохранён и отправлен на почту ${email}`);
    } catch (e) {
      sendGA('Отправить_err', 'check');
      toastErrorNotification('Не удалось отправить документ');
    }
  }

  @action getBalance = async () => {
    this.balance = await axiosAuthorizedRequest({
      url: `/api/v1/client/company/${UserInfo.data?.company?.id}/balance/?date=${Date.now()}`,
    });
  }

  @computed get getQueryFilterHistory() {
    let queryArr = '?';
    Object.entries(toJS(this.filterHistory)).forEach(([key, value], i) => {
      if (value && key !== 'loading') {
        const currentValue = (key === 'type') ?
          MapTypeHistoryFilter[value as keyof typeof MapTypeHistoryFilter] : value;
        if (queryArr[queryArr.length - 1] !== '?') {
          queryArr += '&';
        }
        queryArr += `${key}=${currentValue}`;
      }
    });
    return queryArr;
  }

  @computed get getQueryFilterInvoice() {
    type Query = {
      [key in string | number]: string;
    };
    const queryArr:Query = {};
    Object.entries(toJS(this.filterInvoice)).forEach(([key, value], i) => {
      if (value && key !== 'loading') {
        queryArr[key] = `${value}`;
      }
    });
    return queryArr;
  }

  @action getCampaigns = async () => {
    try {
      const request = await axiosAuthorizedRequest({
        url: '/api/v1/client/campaigns/',
      });
      const request2 = await axiosAuthorizedRequest({
        url: `/api/v1/client/campaigns/?limit=${request?.total}`,
      });
      this.campaigns = request2.results;
    } catch (e) {
      console.error(e);
    }
  }

  findNameCampaign(id?:number) {
    const currentCampaign = this.campaigns?.find((el:any) => el?.orders[0]?.id === id);
    return currentCampaign?.name;
  }

  @computed get getHistoryWithName() {
    return this.operation_list?.operations?.map((el) => {
      // const name = el.type === 'charge' ? this.findNameCampaign(el.order_id) : null;
      return {
        amount: el.amount,
        date: el.date,
        campaign_name: el.orderName,
        type: el.type,
        type_name: el.type_name,
        order_id: el.order_id,
        orderName: el.orderName,
        orderIdTsms: el.orderIdTsms,
        is_sbp: el.is_sbp,
      };
    });
  }

  @action getHistory = async () => {
    this.filterHistory.loading = true;
    try {
      const res = await axiosBaseRequestAdTech({
        url: `/api/Customers/billingHistory${this.getQueryFilterHistory}`,
      });
      this.operation_list.operations = res.operations;
      this.operation_list.totalCount = res.totalCount;
      this.filterHistory.loading = false;
    } catch (e) {
      toastErrorNotification('Не можем загрузить историю операций. Попробуйте позже.');
    } finally {
      this.filterHistory.loading = false;
    }
  }

  @action loadingHistory = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: `/api/Customers/billingHistory/download${this.getQueryFilterHistory}`,
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(request);
      link.download = `История операций-${request?.size}.xlsx`;
      link.click();
    } catch (e) {
      toastErrorNotification('Не можем загрузить историю операций. Попробуйте позже.');
    }
  }


  @action getPayments = async () => {
    this.filterInvoice.loading = true;
    try {
      this.payments = await adTechRequest({
        url: '/api/Customers/invoices',
        params: { type: 1, ...this.getQueryFilterInvoice },
      });
      this.filterInvoice.loading = false;
    } catch (e) {
      this.filterInvoice.loading = false;
      toastErrorNotification('Не можем скачать счета. Попробуйте позже.');
    }
  }

@action downloadFilterInvoice = async (invoiceNumber:number) => {
  try {
    const request = await axiosBaseRequestAdTech({
      url: `/api/Customers/invoices/${invoiceNumber}`,
      responseType: 'blob',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(request);
    link.download = `Счет-${request?.size}.pdf`;
    link.click();
    sendGA('Скачать', 'check');
  } catch (e) {
    sendGA('Скачать_err', 'check');
    toastErrorNotification('Не можем загрузить счет. Попробуйте позже.');
  }
}
@action sendInvoiceToEmail = async () => {
  try {
    const request = await axiosBaseRequestAdTech({
      url: '/api/Customers/reprint',
      method: 'POST',
      data: {
        email: this.sendInvoiceData.email,
        invoiceNumber: this.sendInvoiceData.invoice_number,
        type: this.sendInvoiceData.type,
      },
    });
    toastAccessNotification(`Счёт успешно сохранён и отправлен на почту ${this.sendInvoiceData.email}`);
    sendGA('Отправить', 'check');
  } catch (e) {
    sendGA('Отправить_err', 'check');
    toastErrorNotification('Не можем отправить. Попробуйте позже.');
  }
}


  @action getInvoices = async (page = 1, params: {[key: string]: string|undefined|number|null}) => {
    let currentPage = page;
    if (JSON.stringify(params) !== this.prevCloseDocsParams) {
      currentPage = 1;
      this.prevCloseDocsParams = JSON.stringify(toJS(params));
    }
    try {
      this.invoices = await axiosBaseRequestAdTech({
        url: '/api/Customers/invoices',
        params: {
          type: 0,
          page: currentPage,
          ...toJS(params),
        },
      });
    } catch (e) {
      toastErrorNotification('Не можем загрузить закрывающие документы. Попробуйте позже.');
    }
  }


  createBill = async ({ amount, email }:CreateBillArgs, isSave?:boolean) => {
    if (!UserInfo?.data?.company?.ban) {
      toastErrorNotification('Мы формируем ваш персональный Профиль, нужно немного подождать. Повторите создание счета позже');
      return;
    }
    try {
      const invoiceNumber = await axiosBaseRequestAdTech({
        url: '/api/Customers/invoice',
        method: 'POST',
        data: {
          amount,
          // email,
        },
      });
      await axiosBaseRequestAdTech({
        url: 'api/customers/reprint',
        method: 'POST',
        data: {
          invoiceNumber,
          email,
          type: 1,
        },
      });
      toastAccessNotification(`Счёт успешно сохранён и отправлен на почту ${email}`);
      if (isSave) {
        sendGA('Сохранить', 'balance');
      } else {
        sendGA('Отправить', 'balance');
      }
    } catch (e) {
      if (isSave) {
        sendGA('Сохранить_err', 'balance');
      } else {
        sendGA('Отправить_err', 'balance');
      }
      toastErrorNotification('Ошибка отправки. Попробуйте позже');
    }
  }

  downloadInvoice = async (amount:number, email: string = '') => {
    try {
      const invoice_number = await axiosBaseRequestAdTech({
        url: '/api/Customers/invoice',
        method: 'POST',
        data: {
          amount,
          // email,
        },
      });
      const request = await axiosBaseRequestAdTech({
        url: `/api/Customers/invoices/${invoice_number}`,
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(request);
      link.download = `Счет-${request?.size}.pdf`;
      link.click();
      sendGA('Скачать', 'check');
    } catch (e) {
      sendGA('Скачать_err', 'check');
      toastErrorNotification('Ошибка отправки. Попробуйте позже');
    }
  }
}

const walletStore = new Wallet();

reaction(
  () => walletStore.balance,
  () => {
    UserInfo.balance = walletStore.balance;
  },
);
export default walletStore;
