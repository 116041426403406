import React from 'react';
import classNames from 'classnames';
import styles from './styles.pcss';

type Props = {
  children?: React.ReactNode | string;
  className?: string;
  id?: string;
  onClick?: () => void,
  role?: string
}

const GreyBlock: React.FC<Props> = ({ children, className = '', onClick, role, id }) => {
  if (role === 'button') {
    return (
      <button type="button" id={id} onClick={onClick} className={classNames(styles.greyBlock, styles.btn, className)}
        role={role}>{children}</button>
    );
  }
  return (
    <div id={id} onClick={onClick} className={`${styles.greyBlock} ${className}`} role={role}>{children}</div>
  );
};

const MainTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h1 className={`${styles.title} ${className}`}>{children}</h1>
  );
};

const MediumTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h2 className={`${styles.title} ${styles.medium} ${className}`}>{children}</h2>
  );
};

const LowTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h4 className={`${styles.title} ${styles.lowTitle} ${className}`}>{children}</h4>
  );
};

const GroupTitle: React.FC<Props> = ({ children, className = '', id }) => {
  return (
    <h4 id={id} className={`${styles.groupTitle} ${className}`}>{children}</h4>
  );
};

const HovesTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h1 className={`${styles.hovesTitle} ${className}`}>{children}</h1>
  );
};

const HovesCardTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h5 className={`${styles.groupTitle} ${styles.hovesCardTitle} ${className}`}>{children}</h5>
  );
};

const SubTitleParagraph: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <p className={classNames(styles.subTitleParagraph, className)}>{children}</p>
  );
};

const Body3: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <h3 className={classNames(className)}>{children}</h3>
  );
};

const DescriptionParagraph: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <p className={classNames(styles.smallText, styles.descriptionParagraph, className)}>{children}</p>
  );
};

const SmallTitle: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <p className={classNames(styles.smallTitle, className)}>{children}</p>
  );
};

const SmallText: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <span className={classNames(styles.smallText, className)}>{children}</span>
  );
};


const FormFieldLabel: React.FC<Props> = ({ children, className = '', id }) => {
  return (
    <label id={id} className={classNames(styles.smallText, styles.formFieldLabel, className)}>{children}</label>
  );
};

export {
  MainTitle,
  MediumTitle,
  SmallText,
  HovesTitle,
  HovesCardTitle,
  SubTitleParagraph,
  LowTitle,
  GroupTitle,
  DescriptionParagraph,
  FormFieldLabel,
  GreyBlock,
  SmallTitle,
  Body3,
};
