import React, { Suspense, lazy } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { observer } from 'mobx-react';
import CreateSenderName from 'redesignSrc/pages/SenderNameCard/CreateSenderName';
import { EditSenderName } from 'redesignSrc/pages/SenderNameCard/EditSenderName';
import { PageLoader } from '../components/common/Loaders';
import PageLayoutTsms from '../redesignSrc/components/PageLayoutTsms';
import { SenderNameSideSheet } from '../redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName';
import {
  FAQ_URL,
  FAQ_URL_SUBPAGE, MESSAGE_TEMPLATES_URL,
  MY_CAMPAIGNS_INVOICE,
  MY_CAMPAIGNS_STATISTIC_URL,
  MY_CAMPAIGNS_URL, NEW_CAMPAIGN_URL, NEW_SENDER, NEW_TEMPLATE, SENDER_NAMES_URL,
} from './constants';

const FaqPage = lazy(() => import('pages/Faq'));
const ProfilePage = lazy(() => import('redesignSrc/pagesTsms/ProfilePage'));
const MessageTemplatesPageTsms = lazy(() => import('redesignSrc/pagesTsms/MessageTemplatesPageTsms'));
const MyCampaignsTsms = lazy(() => import('redesignSrc/pagesTsms/MyCampaignsTsms'));
const MyAudienceTsms = lazy(() => import('redesignSrc/pagesTsms/MyAudienceTsms'));
const NewTemplate = lazy(() => import('redesignSrc/pages/NewTemplate'));
const NewAudience = lazy(() => import ('redesignSrc/pages/NewAudience'));
const SenderNamesPageTsms = lazy(() => import ('redesignSrc/pagesTsms/SenderNamesPageTsms'));
const ContractPage = lazy(() => import ('redesignSrc/pagesTsms/СontractPage'));
const CustomerPage = lazy(() => import ('redesignSrc/pagesTsms/CustomerPage'));
const MyCampaignsStatisticPage = lazy(() => import ('pages/MyCampaignsStatistic'));
const InvoicePage = lazy(() => import('redesignSrc/pagesTsms/InvoicesPage'));
const CreateCampaign = lazy(() => import ('redesignSrc/pages/CreateCampaign'));


const RouterPage = (
  props: {
    pageComponent: JSX.Element,
    exact?: boolean,
    prevPathname?: string,
  } & RouteComponentProps,
) => props.pageComponent;


const RoutesWithTsmsAuth = () => {
  return (
    <>
      <Suspense fallback={<PageLoader isLoading/>}>
        <PageLayoutTsms>
          <Router basepath="/" primary={false}>
            <Redirect path="/" from="/" to={MY_CAMPAIGNS_URL} noThrow/>
            <RouterPage pageComponent={<MyCampaignsTsms/>} path="/my-campaigns" default/>
            <RouterPage pageComponent={<MyAudienceTsms/>} path="/my-audiences"/>
            <RouterPage pageComponent={<NewAudience isMyAudience isTSMS/>} path="/new-audience/*"/>
            <RouterPage pageComponent={<MessageTemplatesPageTsms/>} path={MESSAGE_TEMPLATES_URL}/>
            <RouterPage pageComponent={<NewTemplate isTemplateMessage/>} path={`${NEW_TEMPLATE}*`}/>
            <RouterPage pageComponent={<SenderNamesPageTsms/>} path={SENDER_NAMES_URL}/>
            <RouterPage pageComponent={<CreateSenderName/>} path={NEW_SENDER}/>
            <RouterPage pageComponent={<EditSenderName isTemplateMessage/>} path={`${NEW_SENDER}:id`}/>
            <RouterPage pageComponent={<NewAudience isTSMS/>} path="/new-audience/tsms/*"/>
            <RouterPage pageComponent={<ContractPage/>} path="/contract/:id"/>
            <RouterPage pageComponent={<ProfilePage/>} path="/user-info"/>
            <RouterPage pageComponent={<CustomerPage/>} path="/customers/:ban"/>
            <RouterPage pageComponent={<FaqPage/>} path={FAQ_URL}/>
            <RouterPage pageComponent={<FaqPage/>} path={FAQ_URL_SUBPAGE}/>
            <RouterPage pageComponent={<MyCampaignsStatisticPage isTsms/>} path={MY_CAMPAIGNS_STATISTIC_URL}/>
            <RouterPage pageComponent={<InvoicePage/>} path={MY_CAMPAIGNS_INVOICE}/>
            <RouterPage pageComponent={<CreateCampaign isTSMS/>} path={`${NEW_CAMPAIGN_URL}*`}/>
          </Router>
        </PageLayoutTsms>
      </Suspense>
    </>
  );
};

export default observer(RoutesWithTsmsAuth);
