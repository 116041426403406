import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
// @ts-ignore
import qs from 'query-string';
// @ts-ignore
import debounce from 'debounce';
import classNames from 'classnames/bind';
import { Link, navigate } from '@reach/router';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { scrollSmoothTo } from 'utils/scroll';
import { MainTitle } from 'redesignSrc/UI/typography';
import { PageLoader } from 'components/common/Loaders/components';
import TextInput, { IconStart } from 'redesignSrc/UI/form/TextInput';
import DatePicker from 'redesignSrc/UI/form/DatePicker';
import { Section, Row, Column } from 'redesignSrc/UI/layout';
import DropdownLinks, { CheckBoxList } from 'redesignSrc/components/DropdownLinks';
import { InViewPortDetector } from 'components/helpers';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { GlobalIcon } from 'components/common';
import danglingPrepositions from 'utils/danglingPrepositions';
import { Heading } from 'components/layouts';
import UserInfo from 'store/mobx/UserInfo';
import confirmDialog from 'components/modals/confirmDialog';
import styles from './styles2.pcss';
import MenuClientBase from './components/MenuClientBase/MenuClientBase';
import {
  DelModal,
  EditDeleteTemplateGroupModal,
  ShowTemplateCompanyModal,
  ShowTemplateDocsModal,
} from './components/ClientBase/components';
import { TEMPLATE_MESSAGE_STATUS_MAP } from './consts';
import TemplateGroupSearchFilter from './components/TemplateGroupSearchFilter';
import DeleteModal from './components/DeleteModal';

export const MessageTemplatesPage = observer(() => {
  const [loading, setLoading] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenChannels, setIsOpenChannels] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    (async () => {
      await UserInfo.getUser();
      if (!UserInfo.data?.company?.ban) navigate('/user-info/');
    })();
  }, []);

  useEffect(() => {
    const loadMyCampaignPageData = async () => {
      setLoading(true);
      try {
        await messageTemplatesStore.getStatuses();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        scrollSmoothTo(0);
      }
    };

    loadMyCampaignPageData();
  }, []);

  useEffect(() => {
    (async () => {
      messageTemplatesStore.resetForm();
      messageTemplatesStore.resetPages();
      messageTemplatesStore.resetFilters();
      await messageTemplatesStore.getAllMessageTemplates();
      await messageTemplatesStore.getAllMessageTemplatesGroup();
      setLoading(false);
    })();
    return () => {
      messageTemplatesStore.resetPages();
    };
  }, []);

  useEffect(() => {
    messageTemplatesStore.resetPages();
  }, [
    messageTemplatesStore.filter.endDate,
    messageTemplatesStore.filter.name.length,
    messageTemplatesStore.filter.startDate,
  ]);

  useEffect(() => {
    messageTemplatesStore.resetPages();
    (async () => {
      if (!isFirstRender) {
        await messageTemplatesStore.getAllMessageTemplates();
      }
    })();
    setIsFirstRender(false);
  }, [
    messageTemplatesStore.filter.groups,
    messageTemplatesStore.filter.statuses.length,
  ]);

  const draftNameReplacer = (name: string) => name === 'Отклонен' && 'Не согласован' || name;

  const statusesForCheckbox = useMemo(() => {
    const statuses = messageTemplatesStore.statuses.map(
      (item: { value: string, id: number, description: string }) =>
        ({
          label: draftNameReplacer(item.description),
          value: item.id,
          isChecked: messageTemplatesStore.filter.statuses.includes(item.id),
        }
        ));
    return {
      statuses,
      filtersApplied: statuses.filter((status: { isChecked: boolean }) => !status.isChecked),
    };
  }, [messageTemplatesStore.statuses.length, messageTemplatesStore.filter.statuses.length]);

  const channelsForCheckbox = useMemo(() => {
    const statuses = messageTemplatesStore.messageTemplatesGroup.map(
      (item: { name: string, description: string, id: number }) =>
        ({ label: item.name, value: item.id, isChecked: messageTemplatesStore.filter.groups.includes(item.id) }
        )).filter((item) => item.label !== 'Без группы');
    return {
      statuses,
      filtersApplied: statuses.filter((status: { isChecked: boolean }) => !status.isChecked),
    };
  }, [messageTemplatesStore.messageTemplatesGroup.length, messageTemplatesStore.filter.groups.length]);

  const selectedStatusesCount = statusesForCheckbox.statuses.length - statusesForCheckbox.filtersApplied.length;
  const selectedChannelsStatuses = channelsForCheckbox.statuses.length - channelsForCheckbox.filtersApplied.length;

  const createNewTemplateHandler = async () => {
    messageTemplatesStore.resetForm();
    await messageTemplatesStore.initialCreateTemplate();
  };
  const onClose = () => {
    messageTemplatesStore.showDelTemplate = false;
  };

  const onSuccess = async (id: number) => {
    await messageTemplatesStore.deleteTemplateById(id);
    onClose();
  };

  return (
    <PageLoader isLoading={loading} className={styles.container}>
      <Section className={styles.section}>
        <MainTitle className={styles.title}>Шаблоны сообщений</MainTitle>
        <Heading level={5} className={styles.description}>
          {danglingPrepositions('Создавайте шаблоны сообщений для быстрого запуска рекламных кампаний')}
        </Heading>
        <div className={styles.addNewTemplateBtnWrapper}>
          <Button
            startIcon={<Icon iconName={Icons.Add}/>}
            variant="contained"
            size="medium"
            onClick={createNewTemplateHandler}
          >
            Создать новый шаблон
          </Button>
        </div>
        {
          messageTemplatesStore.isLoading && <div className={styles.placeholder}/>
        }
        <InputsList/>
        <Row className={styles.bar}>
          <Column className={styles.column1}>
            <span className={`${styles.name} ${styles.menuList}`}>Название</span>
          </Column>


          <Column className={styles.column2}>
            <span className={styles.menuList}>Статус:
              <span className={styles.dropdown} onClick={() => {
                setIsOpenChannels(false);
                setIsOpenStatus(true);
              }}>
                {
                  selectedStatusesCount > 0 &&
                  ` Выбрано ${selectedStatusesCount}` ||
                  ' Все'
                }
                {
                  isOpenStatus && statusesForCheckbox.statuses.length &&
                  <DropdownLinks
                    isOpen
                    onClose={() => setIsOpenStatus(false)}
                    className={styles.linksContainer}
                    dropDownClass={styles.dropdownElement1}
                  >
                    <CheckBoxList
                      items={statusesForCheckbox.statuses}
                      onItemClick={async (param: string | number) => {
                        if (messageTemplatesStore.filter.statuses.includes(+param)) {
                          // тут игнор, потому что TS не знает, что это псевдо массив
                          // @ts-ignore
                          messageTemplatesStore.filter.statuses.remove(param);
                        } else {
                          messageTemplatesStore.filter.statuses.push(+param);
                        }
                      }}
                    />
                  </DropdownLinks> || null
                }
              </span>
            </span>
          </Column>

          <Column className={styles.column3}>
            <span className={styles.menuList}>Группа:
              <span className={styles.dropdown} onClick={() => {
                setIsOpenStatus(false);
                setIsOpenChannels(true);
              }}>
                {
                  selectedChannelsStatuses > 0 &&
                  ` Выбрано ${selectedChannelsStatuses}` ||
                  ` Все(${channelsForCheckbox.statuses.length})`
                }
                {
                  <OutsideClickTracker onClickOutside={() => setIsOpenChannels(false)}>
                    <TemplateGroupSearchFilter
                      isOpen={isOpenChannels}
                      data={messageTemplatesStore.messageTemplatesGroup}
                      onDelete={async (e) => {
                        setIsOpenChannels(false);
                        const isDelete = await confirmDialog(DeleteModal,
                          {
                            title: 'Удаление группы шаблонов',
                            description: 'Вы действительно хотите удалить группу? Все шаблоны будут сохранены без группы',
                          });
                        if (isDelete) {
                          messageTemplatesStore.deleteTemplateGroupsById(e.id);
                        }
                      }}
                      onChange={(e) => {
                        if (e) {
                          messageTemplatesStore.filter.groups = [e.id];
                        } else {
                          messageTemplatesStore.filter.groups = [];
                        }
                        setIsOpenChannels(false);
                      }}
                    />
                  </OutsideClickTracker>
                  || null
                }
              </span>
            </span>
          </Column>

          <Column className={styles.column4}>
            <span className={styles.menuList}>Дата создания</span>
          </Column>
        </Row>

        {
          messageTemplatesStore.messageTemplates.templates.length < 1 && !messageTemplatesStore.isLoading &&
          <p className={styles.emptyNote}>Ничего не найдено</p>
        }

        <CampaignsList/>

        <div>
          <ShowTemplateDocsModal showModal={messageTemplatesStore.showTemplateDocs}/>
          {/* <DelModal open={messageTemplatesStore.showDelTemplate} onSuccess={onSuccess}/> */}
          <DelModal
            isLoading={messageTemplatesStore.loading}
            title="Удаление шаблона"
            description="Вы действительно хотите удалить этот шаблон?"
            confirmLabel="Оставить"
            cancelLabel="Удалить"
            open={messageTemplatesStore.showDelTemplate}
            onSuccess={onClose}
            onClose={onClose}
            onCancel={() => onSuccess(messageTemplatesStore.selectedTemplateId)}
            footer
          />
          <EditDeleteTemplateGroupModal showModal={messageTemplatesStore.showTemplateEditGroup}/>
          <ShowTemplateCompanyModal showModal={messageTemplatesStore.showTemplateCompany}/>
        </div>
      </Section>
      {(messageTemplatesStore.messageTemplates?.templates?.length !== 0) &&
        <InViewPortDetector onIntersectIn={messageTemplatesStore.getMoreTemplates}/>}
    </PageLoader>
  );
});

const getAllMessageTemplatesDebounce = debounce(messageTemplatesStore.getAllMessageTemplates, 500);

export const InputsList = observer(() => {
  return (
    <div className={styles.wrapperSearch}>
      <TextInput
        startIcon={IconStart.search}
        name="search"
        className={styles.search}
        placeholder="Поиск по названию"
        onChange={async (value) => {
          messageTemplatesStore.filter.page = 1;
          messageTemplatesStore.filter.name = value;
          getAllMessageTemplatesDebounce();
        }}
        value={messageTemplatesStore.filter.name}
      />
      <div className={styles.datesHolder}>
        <DatePicker
          onStartDateClick={async () => {
            messageTemplatesStore.filter.startDate = undefined;
          }}
          onEndDateClick={async () => {
            messageTemplatesStore.filter.endDate = undefined;
          }}
          name="dates_search"
          width={250}
          isInputsReadOnly
          secondMarginLeft={8}
          onChange={async value => {
            messageTemplatesStore.filter.page = 1;
            messageTemplatesStore.filter.startDate = value[0]?.format('YYYY-MM-DD').substring(0, 10);
            messageTemplatesStore.filter.endDate = value[1]?.format('YYYY-MM-DD').substring(0, 10);
            getAllMessageTemplatesDebounce();
          }}
          value={[messageTemplatesStore.filter.startDate, messageTemplatesStore.filter.endDate]}
        />
      </div>
    </div>
  );
});

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Завершен':
    case 'Создан':
    case 'На согласовании':
    case 'Действующий':
    case 'Черновик':
      return styles.active;
    case 'Модерация не пройдена':
    case 'Отказ':
      return styles.inactive;
    default:
      return styles.inProgress;
  }
};

export const modifyStatus = (status: string) => {
  return status;
};

const noName = (name: string) => {
  return name || 'Без названия';
};

const CampaignsList = observer(() => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<any>(0);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(0);
  const [showTemplateDocs, setShowTemplateDocs] = useState(false);
  const [showTemplateCompany, setShowTemplateCompany] = useState(false);
  const [showEditDelGroupModal, setShowEditDelGroupModal] = useState(false);
  const [showTemplateAddGroup, setShowTemplateAddGroup] = useState(false);
  const [showTemplateEditGroup, setShowTemplateEditGroup] = useState(false);

  const editGroupByHoverHandler = (id: number | null) => {
    if (id) {
      messageTemplatesStore.showTemplateEditGroup = true;
      messageTemplatesStore.selectedTemplateGroupId = id;
    }
  };

  return (
    <>
      {
        messageTemplatesStore.messageTemplates.templates?.length && messageTemplatesStore.messageTemplates.templates.map((template) => {
          return (
            <>
              <div className={styles.wrapList}>
                <Row className={styles.dataRow} key={template.id}>
                  <Link to={`/new-template/${template.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column1}>
                      <span
                        className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{template.name}</span>
                    </div>
                  </Link>
                  <Link to={`/new-template/${template.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column2}>
                      <span className={`${styles.columnText} ${styles.status} ${template.status}`}>
                        {TEMPLATE_MESSAGE_STATUS_MAP[template.status]}
                      </span>
                    </div>
                  </Link>

                  <div className={styles.column3}>
                    {
                      !template.groupName ? '-' : (
                        <span className={classNames(styles.groupNameTd)}>
                          <span
                            onClick={() => editGroupByHoverHandler(template.groupId)}>{template.groupName}{template.groupName ?
                              <i className={classNames(styles.editIcon)}/> : null}
                          </span>
                        </span>
                      )
                    }
                  </div>


                  <Link to={`/new-template/${template.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column4}>
                      <div className={`${styles.columnText} ${styles.campaignName}`}>
                        {moment(template.createDate).format('DD.MM.YYYY')}
                        {
                          (template.unseenComments &&
                            template.status === 'Rejected' &&
                            <div className={styles.unseenComments}>{template.unseenComments}</div>) || null
                        }
                      </div>
                    </div>
                  </Link>
                  <div className={classNames(styles.arrowWrap)}>
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(styles.moreActions)}
                      onClick={() => {
                        setMenuVisible(!isMenuVisible);
                        setSelectedMenu(template.id || 0);
                        messageTemplatesStore.selectedTemplateId = template.id || 0;
                        setSelectedTemplateId(template.id);
                      }}
                    >
                      <GlobalIcon slug="dotsMenu" className={styles.moreDots}/>

                    </div>

                    <div>
                      {isMenuVisible ?
                        <MenuClientBase
                          isMenuVisible={selectedMenu === template.id}
                          selectedTemplateId={selectedTemplateId}
                          onClose={() => setMenuVisible(false)}
                          id={template.id || 0}
                          groupId={template.groupId}
                          name={template.name}
                          count={0}
                          status={template.status}
                          setShowTemplateDocs={setShowTemplateDocs}
                          setShowTemplateCompany={setShowTemplateCompany}
                          setShowTemplateAddGroup={setShowTemplateAddGroup}
                          setShowTemplateEditGroup={setShowTemplateEditGroup}
                        /> : null}
                    </div>
                  </div>
                </Row>
              </div>
            </>
          );

          return (
            <div className={styles.wrapList}>
              <Row className={styles.dataRow} key={template.id}>
                <div className={styles.column1}>
                  <span
                    className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{noName(template.name)}</span>
                </div>

                <div className={styles.column2}>
                  <span className={`${styles.columnText} ${styles.status} ${template.status}`}>
                    {template.status}
                  </span>
                </div>

                <div className={styles.column3}>
                  <p className={`
                    ${styles.columnText} ${styles.campaignType}
                  `}>
                    {template.status}
                  </p>
                </div>

                <div className={styles.column4}>
                  <span className={`${styles.columnText} ${styles.campaignName}`}>
                    {template.status}
                  </span>
                </div>
              </Row>
              <div/>
            </div>
          );
        }) || null
      }
    </>
  );
});
