import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Divider, IconButton, PopoverHelper } from '@beeline/design-system-react';
import classNames from 'classnames/bind';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { toJS } from 'mobx';
import NewAudiences from 'store/mobx/NewAudiences';
import NewOrder from 'store/mobx/NewOrder';
import { formatPriceTotal } from 'utils/formatting';
import Common from 'store/mobx/Common';
import styles from './style.pcss';
import TextAudience from './TextAudience';
import useUtilsCount from './useUtilsCount';

type Props = {
  isCampaign?: boolean,
}

const SmsWidgetPrice: React.FC<Props> = ({ isCampaign }) => {
  const [togglePrice, setToggleprice] = useState<boolean>(false);
  const isShowLineAndToggle = !!NewOrder.calculateData?.total;
  const {
    renderBaseSmsPriceForOtherOper,
    showTitlePrice,
    renderTotalCost,
    showTooltipText1,
    showTooltipText2,

  } = useUtilsCount();

  const isToggleState = ((!isCampaign && !NewAudiences.isNotRenderPrice) ||
    NewAudiences.isSimple ||
    NewAudiences.isCustom ||
    isShowLineAndToggle);

  const renderPriceSegment = () => {
    return (
      <div className={styles.priceSegment}>
        {NewAudiences.renderPriceSegment.map(({ price, title }) => {
          return (
            <p key={`${title}-${price}`} className={styles.description}>
              {title}
              <span>{formatPriceTotal(price)}</span>
            </p>
          );
        })}
      </div>
    );
  };

  const renderMainPriceSms = () => {
    const geoPrice = NewAudiences.selectedSegments?.find(segment => segment.bundleType === 'Geo')?.price || '-';
    if (NewAudiences.isMultiOperators) {
      return (
        <>
          <div className={styles.wrapMultiOp}>
            <p className={styles.multiOp}>билайн</p>
            <div className={classNames(styles.wrapPriceSms, styles.isMulOper)}>
              <span>1 SMS</span>
              <div className={styles.alignCenter}>
                <span>{showTitlePrice()}</span>
                <PopoverHelper
                  body={Common.settings?.repriceVersion === 2 ? showTooltipText2() : showTooltipText1()}
                >
                  <IconButton iconName={Icons.InfoCircled}/>
                </PopoverHelper>
              </div>
            </div>
          </div>
          {isCampaign ? !togglePrice && renderTotalCost() :
            !NewOrder.calculateData?.isFixedPrice && !NewAudiences.isSimple && !togglePrice &&
            renderPriceSegment()
          }
          <Divider className={styles.lineSms}/>
          <div className={styles.wrapMultiOp}>
            <p className={styles.multiOp}>Другие операторы</p>

            <div className={classNames(styles.wrapPriceSms, styles.isMulOper)}>
              <span>1 SMS</span>
              <div className={styles.alignCenter}>
                <span>{renderBaseSmsPriceForOtherOper()}</span>
                <PopoverHelper
                  body={Common.settings?.repriceVersion === 2 ? showTooltipText2(true) : showTooltipText1(true)}
                >
                  <IconButton iconName={Icons.InfoCircled}/>
                </PopoverHelper>
              </div>
            </div>
            {NewOrder.countMessageOther && !togglePrice && isCampaign ?
              <p className={styles.descriptionSms}>
                Количество
                <span>
                  {NewOrder.countMessageOther}
                </span>
              </p> : null
            }
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.wrapPriceSms}>
          <span>1 SMS</span>
          <div className={styles.alignCenter}>
            <span>{showTitlePrice()}</span>
            <PopoverHelper
              body={Common.settings?.repriceVersion === 2 ? showTooltipText2() : showTooltipText1()}
            >
              <IconButton iconName={Icons.InfoCircled}/>
            </PopoverHelper>
          </div>
        </div>
        {isCampaign ? renderTotalCost() :
          !NewOrder.calculateData?.isFixedPrice && !NewAudiences.isSimple && !togglePrice &&
          renderPriceSegment()
        }
        {NewAudiences.isCustom && !togglePrice &&
          <div className={styles.priceSegment}>
            <p className={styles.description}>
              Параметры инд. аудитории
              <span>{formatPriceTotal(NewAudiences.OTHER_PRICE)}</span>
            </p>
          </div>
        }
        {NewAudiences.isSimple && !togglePrice &&
          <div className={styles.priceSegment}>
            <p className={styles.description}>
              Подбор аудитории
              <span>{formatPriceTotal(geoPrice)}</span>
            </p>
          </div>
        }
      </>
    );
  };

  return (
    <>
      <Card
        elevation="medium"
        className={styles.container}
      >
        <TextAudience/>
      </Card>
      <Card
        elevation="medium"
        className={classNames(styles.container, styles.container1,
          isToggleState && styles.paddingBottom)}
      >
        <p className={classNames(styles.header, styles.header1, !isShowLineAndToggle && styles.correctMargin1)}>
          {isCampaign ? 'Расчет стоимости' : 'Стоимость при заведении кампании'}
          {NewAudiences.isMultiOperators &&
            <PopoverHelper
              title="Расчет стоимости приблизительный"
              body="Окончательный расчет будет предоставлен после согласования у всех операторов"
            >
              <IconButton iconName={Icons.InfoCircled}/>
            </PopoverHelper>
          }
        </p>
        {renderMainPriceSms()}

        {isToggleState &&
          <>
            <IconButton
              className={styles.toggleIcon}
              iconName={togglePrice ? Icons.NavArrowDown : Icons.NavArrowUp}
              onClick={() => setToggleprice(!togglePrice)}
            />
          </>

        }
      </Card>
    </>
  );
};

export default observer(SmsWidgetPrice);
