import React from 'react';
import { observer } from 'mobx-react';
import { Button, InlineAlert } from '@beeline/design-system-react';
import { navigate } from '@reach/router';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import sideSheetStore from 'store/mobx/SideSheetStore';
import { questionsState } from '../../store';
import styles from './styles.pcss';

const LastScreen = observer(() => {
  return (
    <div className={styles.contentWrapper}>
      <TypographyWithDangling
        variant="h6"
        className={styles.finalTitleText}
      >
        {questionsState.surveys?.resultTitle}
      </TypographyWithDangling>
      <img
        src={questionsState.surveys?.resultImageLink}
        alt={questionsState.surveys?.resultTitle || ''}
        className={styles.lastImg}
      />
      <div className={styles.blueBlock}>
        {
          questionsState.surveys?.resultMainText &&
          <TypographyWithDangling variant="body2" className={styles.infoText}>{questionsState.surveys?.resultMainText}</TypographyWithDangling>
        }
        {
          questionsState.surveys?.resultRewardText &&
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: questionsState.surveys?.resultRewardText as string }} />
        }
        {
          questionsState.surveys?.resultHintText1 &&
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: questionsState.surveys?.resultHintText1 as string }} /> ||
          null
        }
      </div>
      {
        questionsState.surveys?.resultHintText2 &&
        <InlineAlert className={styles.alert}>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: questionsState.surveys?.resultHintText2 as string }} />
        </InlineAlert> || null
      }

      <div className={styles.buttonsHolder}>
        <Button
          variant="contained"
          onClick={() => {
            questionsState.isOpened = false;
            sideSheetStore.componentToShow = null;
            navigate('/new-campaign/');
          }}
        >
          Создать кампанию
        </Button>
      </div>
    </div>
  );
});

export default LastScreen;
