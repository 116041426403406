import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';
import classNames from 'classnames';
import PageLoader from 'redesignSrc/UI/loaders/PageLoader';
import { ContentBlock, WhiteBlockCollapse } from 'redesignSrc/UI/elements/ContentLayout';
import ContractSelectionStore from 'redesignSrc/components/ContractSelection1/ContractSelectionStore';
import { TypeBanCustomer } from 'redesignSrc/components/ContractSelectionSS/ContractSelectionSSStore';
import { MainTitle } from 'redesignSrc/UI/typography';
import {
  getDiscountInfo,
  DiscountInfoData,
  StatisticBanType,
  getStatisticInfo,
} from 'redesignSrc/pages/NewAudience/components/CheckoutModal/requests';
import BackLink from 'pages/MyCampaignsStatistic/ChannelsPages/components/BackLink';
import TableStatistic from './components/TableStatistic';
import DiscountCard from './components/DiscountCard';
import styles from './styles.pcss';

const BanAggregator = () => {
  const [ban, setBan] = useState<TypeBanCustomer | null>(null);
  const [discount, setDiscount] = useState<DiscountInfoData | null>(null);
  const [statistic, setStatistic] = useState<StatisticBanType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (ContractSelectionStore.bans.length === 0) {
        await ContractSelectionStore.getBans();
      }
      const currentBan = ContractSelectionStore.bans.find(el => (el.ban === id)) || null;
      if (currentBan) {
        const currentStatistic = await getStatisticInfo(currentBan.ban, currentBan.customerId);
        if (currentStatistic) {
          setStatistic(currentStatistic.statistic);
        }
      }
      if (currentBan?.isPostPay) {
        const currentDiscount = await getDiscountInfo(currentBan.ban);
        if (currentDiscount) {
          setDiscount(currentDiscount);
        }
      }
      setBan(currentBan);
      setIsLoading(false);
    })();
  }, []);

  return (
    <PageLoader isLoading={isLoading} className={styles.wrap}>
      <BackLink to="/user-info/bans/"/>
      <MainTitle className={styles.mainTitle}>Договор</MainTitle>
      <WhiteBlockCollapse
        title="Данные договора"
        className={styles.wrapBlock}
      >
        <div className={styles.mainWrap}>
          <div className={classNames(styles.wrapContent, ban?.isPostPay && styles.isPostPay)}>
            <ContentBlock
              label="№ Договора"
              text={ban?.ban}
            />
            <ContentBlock
              label="Система расчета"
              text={ban?.isPostPay ? 'постоплатная' : 'авансовая'}
            />
          </div>
          {ban?.isPostPay &&
            <DiscountCard
              discount={discount}
              cost={statistic?.length && statistic[0].priceSum}
            />
          }
        </div>

      </WhiteBlockCollapse>

      <WhiteBlockCollapse
        title="Статистика по SMS-кампаниям"
        className={styles.wrapBlock}
      >
        <TableStatistic data={statistic}/>
      </WhiteBlockCollapse>

    </PageLoader>
  );
};

export default observer(BanAggregator);
