import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { action } from 'mobx';
import { TypographyWithDangling } from '../../../../../../../UI/beeline/TypographyWithDangling/TypographyWithDangling';
import styles from '../styles.pcss';
import { fileNaming } from '../../../../../../../../utils/formatting';

const UploadedFiles = observer(({
  title,
  onShowList,
  filesLength,
  onAddFiles,
  documentTypeId,
}: {
  title: string,
  onShowList: () => void,
  filesLength: number,
  onAddFiles: (e:React.ChangeEvent<HTMLInputElement>, id: number | string) => void,
  documentTypeId: number,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={styles.uploadedFile}>
      <div className={styles.addFile}>
        <TypographyWithDangling variant="body2">
          {title}
        </TypographyWithDangling>
        <Button
          variant="plain"
          className={styles.plusItem}
          type="button"
          onClick={() => {
            if (inputRef.current) {
              inputRef?.current?.click();
            }
          }}
        >
          <Icon iconName={Icons.Add} />
          <input
            type="file"
            multiple={false}
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={action((e) => {
              onAddFiles(e, documentTypeId);
            })}
          />
        </Button>
      </div>
      <Button
        endIcon={<Icon iconName={Icons.ArrowRight} />}
        variant="plain"
        onClick={onShowList}
        type="button"
      >
        Посмотреть {filesLength} {fileNaming(filesLength)}
      </Button>
    </div>
  );
});

export default UploadedFiles;
