import { observable, runInAction, computed, action, toJS } from 'mobx';
import { requestFocusReports, requestCreateReport, requestAddFileToReport, requestUpdateReport, requestFocusOrders, requestReport } from 'requests/audienceAnalysis';
import { toFormData } from 'utils/forms';
import { customerDiscounts } from 'store/mobx/CustomerDiscounts/CustomerDiscounts';
import NewOrder from 'store/mobx/NewOrder';
import { axiosBaseRequestAdTech } from '../../requests/helpers';

export const DEFAULT_PAGE_SIZE = 10;

class AudienceAnalysis {
  @observable focusReportsList = [];
  @observable isLoadingFile = false;
  @observable isReport = false;
  @observable orderId = null;
  @observable isLoadingPromo = false;
  @observable promocode = null;
  @observable discount = null;
  @observable isSale = null;
  @observable discountCnt = 0;
  @observable pageCount = 1;

  @action updatePromo = async (reportId) => {
    try {
      if (this.promocode) {
        this.isLoadingPromo = true;
        const response = await axiosBaseRequestAdTech({
          url: `/api/orders/${this?.orderId}/checkPromocode?promocode=${this.promocode}&reportTypeId=${reportId}`,
        });
        this.discount = response.orderDiscount;
        this.isSale = true;
      }
    } finally {
      this.isLoadingPromo = false;
    }
  }

  @action set = (value, property) => {
    this[value] = property;
  };

  @computed get focusReports() {
    return toJS(this.focusReportsList);
  }

  getFocusReports = async () => {
    try {
      const focusReports = await requestFocusReports();
      if (focusReports?.length) {
        runInAction(() => {
          this.focusReportsList = focusReports;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getReport = async ({ orderId }) => {
    let result = null;
    try {
      const report = await requestReport({ orderId });
      if (Object.keys(report)?.length) {
        result = report;
      }
    } catch (error) {
      console.error(error);
    }
    return result;
  };

  getFocusOrders = async (page = 1) => {
    try {
      return await requestFocusOrders({ page });
    } catch (error) {
      console.error(error);
    }
  };

  createReport = async ({ data } = {}) => {
    let id = null;
    const dataWithAdditionalSettings = { ...data, type: 'Focus', IsAutoInvoice: true };

    try {
      const request = await requestCreateReport({ data: dataWithAdditionalSettings });
      if (Number.isInteger(request)) {
        id = request;
        this.isReport = true;
        this.orderId = id;
      }
    } catch (error) {
      console.error(error);
    }
    return id;
  };

  updateReport = async ({ data, orderId } = {}) => {
    const result = { isSuccess: false };
    const dataWithAdditionalSettings = { ...data, type: 'Focus', IsAutoInvoice: true, planBonusValue: customerDiscounts.isClickBtnBonuses ? customerDiscounts.planBonusValue : null };
    try {
      await requestUpdateReport({ data: dataWithAdditionalSettings, orderId });
      NewOrder.calculate(orderId);
      result.isSuccess = true;
    } catch (error) {
      console.error(error);
    }
    return result;
  };

  addFileToReport = async ({ data, orderId }) => {
    let fileToken = null;
    const formData = toFormData({ ...data });

    try {
      this.isLoadingFile = true;
      const request = await requestAddFileToReport({ data: formData, orderId });
      if (Object.keys(request)?.length) {
        fileToken = request;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingFile = false;
    }
    return fileToken;
  };
}

const audienceStore = new AudienceAnalysis();

export default audienceStore;
