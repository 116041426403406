import { observable, action, reaction, toJS, autorun, computed } from 'mobx';
import React from 'react';
import NewOrder from 'store/mobx/NewOrder';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { wordFormByCount } from 'utils/fn';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import {
  TypeValue,
  TypeLinkValue,
  MaskLink,
  TypeFilePromo,
  promoForm,
  TypePosition,
  TypeFocusVariables, TypeCurrentValueVariable,
} from './types';
import { uniqueTypeVariable } from './utils/utils';

class Store {
  // Эта переменная сделанна для предотвращения лишних рендеров если текст не изменился
  // если меняем editorState вне события handleChange то вручную проставляем false
  @observable isSameText = false
  @observable isOpenDynamicLink = false

  @observable timeLink = ''
  @observable timeDop = ''
  @observable previewLink = ''
  @observable isChangeCheckbox = false

  @observable oddLoaded = false
  @observable timerId = null;
  @observable.ref maxLength = null
  @observable.ref promo = '[промо]'
  @observable value: TypeValue = {
    text: null,
    originText: '',
    links: {
      link: null,
      shortLink: null,
      isShort: false,
      dynamicPartLink: null,
    },
  }
  @observable isOpenPopupLink = false
  @observable focus: number | null = null
  @observable linkValue: TypeLinkValue = {
    link: null,
    isShort: false,
    shortLink: 'https://beel.ink/*****',
    mask: MaskLink,
    dynamicPartLink: null,
  }
  @observable blockDrag: string | null = null
  @observable isOpenMenuLink = false
  @observable isOpenMenuPromo = false

  // часть стора для PromoBlock
  @observable filePromo: TypeFilePromo | null = null
  @observable infoPromo: React.ReactNode | null = null
  @observable errorPromo: string | null = null
  @observable isOpenPopupPromo = false
  @observable valid: any = null
  @observable posXYPromo: TypePosition = {
    x: 0,
    y: 0,
  }

  // Текущий id удаляемого шаблона и чипсины
  @observable deleteIdVariables: string | null = null

  // Текущий id добавляемого шаблона и чипсины
  @observable currentIdVariables: string | null = null

  // Массив всех шаблонов1
  @observable variables: Map<string, string> = new Map()

  // Фокус добавляемых чипсин
  @observable focusVariables: TypeFocusVariables = {
    phone: null,
    discount: null,
    amount: null,
    date: null,
    staticPromocode: null,
    bonus: null,
  }

  // текущее значение редактируемых переменных
  @observable currentValueVariable: TypeCurrentValueVariable = {
    phone: null,
    discount: null,
    amount: null,
    date: null,
    staticPromocode: null,
    bonus: null,
  }

  @action resetLinkValue = () => {
    this.linkValue.link = null;
    this.linkValue.isShort = false;
    this.linkValue.dynamicPartLink = null;
    this.isOpenDynamicLink = false;

    this.timeDop = '';
    this.timeLink = '';
  }

  @action reset = () => {
    this.timeDop = '';
    this.timeLink = '';

    this.oddLoaded = false;
    this.isSameText = false;
    this.isOpenDynamicLink = false;
    this.value = {
      text: null,
      originText: '',
      links: {
        link: null,
        shortLink: null,
        isShort: false,
        dynamicPartLink: null,
      },
    };
    this.filePromo = null;
    this.infoPromo = null;
    this.errorPromo = null;
    this.isOpenPopupPromo = false;
    this.isOpenMenuPromo = false;
    this.valid = null;
    this.resetLinkValue();
    this.currentIdVariables = null;
    this.deleteIdVariables = null;
    this.variables = new Map();
    this.focusVariables = {
      phone: null,
      discount: null,
      amount: null,
      date: null,
      staticPromocode: null,
      bonus: null,
    };
    this.currentValueVariable = {
      phone: null,
      discount: null,
      amount: null,
      date: null,
      staticPromocode: null,
      bonus: null,
    };
  }

  @action resetShortLink = () => {
    store.value.links.link = null;
    store.value.links.shortLink = null;
    store.value.links.isShort = false;
    store.value.links.dynamicPartLink = null;

    store.timeDop = '';
    store.timeLink = '';
  }

  removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  }

  findText = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, this.removeLastWord(url));
  }

  findText2 = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, url + NewOrder.smsCampaign?.links?.dynamicPartLink);
  }

  @action updateStore = () => {
    if (window.location.href.includes('new-template')) {
      // Шаблоны
      if (messageTemplatesStore.formTemplate.values.template) {
        this.value.originText = messageTemplatesStore.formTemplate.values.template;
        this.value.links = messageTemplatesStore.formTemplate.values.links || this.value.links;
        this.value.text = this.value.links?.isShort ?
          messageTemplatesStore.formTemplate.values.shortTemplate :
          messageTemplatesStore.formTemplate.values.template;
        this.linkValue.link = messageTemplatesStore.formTemplate.values.links.link;
        this.linkValue.isShort = messageTemplatesStore.formTemplate.values.links.isShort;
        this.linkValue.dynamicPartLink = messageTemplatesStore.formTemplate.values.links.dynamicPartLink;
        if (messageTemplatesStore.formTemplate.values.variables) {
          this.variables = new Map(Object.entries(messageTemplatesStore.formTemplate.values.variables));
        }
      }
    } else {
      if (NewOrder.smsCampaign.message) {
        this.value.originText = NewOrder.smsCampaign.message;
        this.value.links = NewOrder.smsCampaign.links || this.value.links;
        this.variables = new Map(Object.entries(toJS(NewOrder.smsCampaign.variables || {})));
        this.value.text = this.value.links?.isShort ?
          NewOrder.smsCampaign.shortMessage :
          NewOrder.smsCampaign.message;
      }
      this.linkValue.link = NewOrder.smsCampaign.links.link;

      this.linkValue.isShort = NewOrder.smsCampaign.links.isShort;
      this.linkValue.dynamicPartLink = NewOrder.smsCampaign.links.dynamicPartLink;
    }
  }

  @action deleteFilePromo = async () => {
    if (this.filePromo?.id) {
      await axiosBaseRequestAdTech({
        url: `api/campaign/${NewOrder.smsCampaign.campaignId}/file/${this.filePromo?.id}`,
        method: 'DELETE',

      });
      this.filePromo = null;
      NewOrder.smsCampaign.isParameterized = false;
    }
  }

  @action uploadFilePromo = async () => {
    if (this.filePromo || !NewOrder.smsCampaign.campaignId) return;
    const request = await axiosBaseRequestAdTech({
      url: `api/campaign/${NewOrder.smsCampaign.campaignId}/files?fileType=2`,
    });
    const file = request[0];
    if (file) {
      this.filePromo = {
        name: file?.name,
        id: file?.id,
        rowCount: file?.rowCount,
        declined: 0,
      };
    }
  }

  @action deleteVariable = (typeBlock: string) => {
    this.variables.delete(typeBlock);
    this.deleteIdVariables = typeBlock;
  }

  @action setPromoVariable = (value: string, isDynamic: boolean) => {
    if (isDynamic) {
      this.currentIdVariables = 'dynamicPromocode';
      this.variables.set('dynamicPromocode', 'dynamicPromocode');
    } else {
      this.currentIdVariables = 'staticPromocode';
      this.variables.set('staticPromocode', `"${value}"`);
    }
  }

  @action setVariable = (typeBlock: string, value: string) => {
    // Если typeBlock не содержит id значит мы не редактируем а добавляем новую var
    if (!/\d+/.test(typeBlock)) {
      const uniqueType = uniqueTypeVariable(typeBlock, this.variables);
      this.currentIdVariables = uniqueType;
      this.variables.set(uniqueType, value);
    } else {
      this.variables.set(typeBlock, value);
      this.currentIdVariables = typeBlock;
    }
  }

  // Высчитываем с обьекта Map значения чтобы передать в useEffect в
  // качестве массива зависимостей и при составлении шаблонов передаем пустой массив
  @computed get valueVariables() {
    const currentValue = [];
    for (const value of this.variables.values()) {
      currentValue.push(value);
    }
    return currentValue;
  }
}

const store = new Store();


reaction(
  () => store.linkValue.link,
  () => {
    store.value.links.shortLink = store.linkValue.shortLink;
    store.value.links.link = store.linkValue.link;
    store.value.links.isShort = store.linkValue.isShort;
    store.value.links.dynamicPartLink = store.linkValue.dynamicPartLink;
  },
);

reaction(
  () => NewOrder?.smsCampaign?.isParameterized,
  () => {
    if (NewOrder.smsCampaign.isParameterized && !store.filePromo) {
      store.uploadFilePromo();
    }
  },
);

reaction(
  () => store.isOpenPopupLink,
  () => {
    if (store.isOpenPopupLink) {
      StoreDnd.setBox('link');
    } else StoreDnd.deleteBox('link');
  },
);

reaction(
  () => store.isOpenPopupPromo,
  () => {
    if (store.isOpenPopupPromo) {
      StoreDnd.setBox('promo');
    } else StoreDnd.deleteBox('promo');
  },
);


export default store;
