import { axiosBaseRequestAdTech } from 'requests/helpers';
import { toastErrorNotification } from 'modules/toast-notifications';

export type DiscountInfoData = {
  type: 'FixedPrice' | 'FixedDiscount' | 'DiscountPlan',
  fixedPrice: {
    Push: { partPrice: number, segmentPrice: number }
    Sms: { partPrice: number, segmentPrice: number }
  } | null,
  fixedDiscount: number | null,
  discountPlanId: number | null,
  discounts: { cost: number, discountValue: number }[] | null,
  priceSumByCompletedCampaigns: number | null,
  priceSumByInProgresscampaigns: number | null,
  customSegmentOrderPrice: number | null,
  history: []
}

export type StatisticBanType = {
  period: string,
  campaignsCount: number,
  messagesCount: number,
  priceSum: number,
  discount: number,
  month: number,
  year: number,
}

type RequestTypeStatistic = {
  statistic: StatisticBanType[],
  total: StatisticBanType
}

export const getDiscountInfo = async (ban: string, customerId?: string): Promise<DiscountInfoData | void> => {
  try {
    return await axiosBaseRequestAdTech({
      url: '/api/statistic/discountInfo',
      method: 'GET',
      params: {
        ban,
        customerId,
      },
    });
  } catch (e) {
    toastErrorNotification('При выполнении запроса возникла ошибка, повторите попытку позже');
  }
};

export const getStatisticInfo = async (ban: string | number, customerId: string | number): Promise<RequestTypeStatistic | void> => {
  try {
    return await axiosBaseRequestAdTech({
      url: `/api/customers/${customerId}/statistic?ban=${ban}`,
      method: 'GET',
    });
  } catch (e) {
    toastErrorNotification('При выполнении запроса возникла ошибка, повторите попытку позже');
  }
};
