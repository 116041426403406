import React from 'react';
import classNames from 'classnames';
import { DiscountInfoData } from 'redesignSrc/pages/NewAudience/components/CheckoutModal/requests';
import DiscountPlanChart from '../DiscountPlanChart';
import styles from './styles.pcss';

type Props = {
  discount: DiscountInfoData | null
  cost?: number
}


const DiscountCard: React.FC<Props> = ({ discount, cost }) => {
  if (discount?.type === 'FixedDiscount') {
    return (
      <div className={styles.fixedDiscountWrap}>
        <span>{`скидка ${discount?.fixedDiscount || 0} %`}</span>
      </div>
    );
  }
  if (discount?.type === 'DiscountPlan') {
    return (
      <DiscountPlanChart
        data={discount.discounts || []}
        currentCost={cost}
      />
    );
  }
  if (discount?.type === 'FixedPrice') {
    return (
      <div className={styles.mainWrapFixedPrice}>
        <div className={styles.columFixedPrice}>
          <span className={styles.empty}/>
          <span className={styles.fontValue}>SMS-рассылка</span>
          <span className={styles.fontValue}>PUSH-уведомления</span>
        </div>
        <div className={classNames(styles.columFixedPrice, styles.alignCenter)}>
          <span className={styles.fontTitle}>Базовая стоимость</span>
          <span className={styles.fontValue}>{discount.fixedPrice?.Sms.partPrice}</span>
          <span className={styles.fontValue}>{discount.fixedPrice?.Push.partPrice}</span>
        </div>
        <div className={classNames(styles.columFixedPrice, styles.alignCenter)}>
          <span className={styles.fontTitle}>Сегмент</span>
          <span className={styles.fontValue}>{discount.fixedPrice?.Sms.segmentPrice}</span>
          <span className={styles.fontValue}>{discount.fixedPrice?.Push.segmentPrice}</span>
        </div>
      </div>
    );
  }
  return null;
};

export default DiscountCard;
