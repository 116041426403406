import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import WidgetsWikiLink
  from 'redesignSrc/pages/NewAudience/components/SettingAudience/components/TaxonWidgets/WidgetsWikiLink';
import { ContentContainer } from 'redesignSrc/UI/layout';
import { GroupTitle, HovesCardTitle } from 'redesignSrc/UI/typography';
import ErrorDescription from 'redesignSrc/components/ErrorDescription';
import WordsDescription from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/WordsDescription';
import FloatingSidePanel from 'pages/MyFinances/components/FloatingPanel';
import { SelectTemplateGroup } from '../SelectTemplateGroup/SelectTemplateGroup';
import { FIELDS_TEMPLATE_FORM } from '../../consts';
import Opener from '../../../TargetInternet/wiki/Opener';
import { Wiki40 } from '../../../TargetInternet/wiki';
import TextMessage from './components/TextMessage';
import styles from './styles.pcss';
import { TemplateDescription, LegalInfo } from './components';
import { TemplateCustomizationProps } from './TemplateCustomizationProps';
import ChipsTemplateContainer from './components/ChipsTemplateContainer';

const cx = classNames.bind(styles);

const isDisabledField = () => {
  return messageTemplatesStore.formTemplate.values.status === 'Verification' || messageTemplatesStore.formTemplate.values.status === 'Accepted';
};

export const TemplateCustomization: FC<TemplateCustomizationProps> = observer(({ valid }) => {
  const [wiki5, setWiki5] = useState(false);
  return (
    <ContentContainer className={styles.bottomBlockWithoutBottom}>
      <GroupTitle className={styles.title}>Настройка шаблона</GroupTitle>
      <SelectTemplateGroup valid={valid}/>
      <HovesCardTitle className={styles.msgTitle}>Текст сообщения</HovesCardTitle>
      <div style={{ marginBottom: '20px' }}>
        <WidgetsWikiLink className={styles.wikiLink}
          title="Обязательные требования к тексту для прохождения модерации"/>
      </div>
      <div className={cx({ disabledSmsText: isDisabledField() })}>
        <TextMessage
          error={valid.errors[FIELDS_TEMPLATE_FORM.TEMPLATE]}
          handleSmsFormChange={() => {
          }}
          valid={valid}
        />
      </div>
      {valid.errors?.template !== 'Не показывать' &&
        <ErrorDescription errors={valid.errors} fieldName={FIELDS_TEMPLATE_FORM.TEMPLATE}/>
      }
      <WordsDescription
        isStopWords={messageTemplatesStore.intolerantWords.stopWords.length !== 0 && valid.errors?.template === 'Не показывать'}
        isCautionWords={messageTemplatesStore.intolerantWords.cautionWords.length !== 0}
      />
      {!isDisabledField() && <ChipsTemplateContainer/>}

      <LegalInfo
        labelText="Юридическая информация"
        valid={valid}
      />

      <TemplateDescription valid={valid}/>
      {
        wiki5 &&
        <FloatingSidePanel onClose={() => setWiki5(false)} title="Как использовать шаблоны сообщений?">
          <Opener title="Шаблон сообщения"><Wiki40/></Opener>
        </FloatingSidePanel>
      }
    </ContentContainer>

  );
});
